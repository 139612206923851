import {
    get,
    post,
    put,
    del,
    tkGet,
    tkPost,
    DownloadFile
} from "./request"

export const getTopicsList = (data = {}) => {
    return post(`/TitleRanking/search`, data)
}

export const searchTitleByTitleId = (data = {}, params = {}) => {
    return post(`/TitleRanking/searchTitleByTitleId`, data, params)
}

export const getTopicsDetail = (data = {}) => {
    return post(`/TitleRanking/detail`, data)
}

export const getMembershipPrice = (data = {}) => {
    return post(`/membershipPrice/getMembershipPrice`, data)
}

export const getOrderCode = (data = {}) => {
    return post(`/order/getCode`, data)
}

export const getOrderStatus = (data = {}) => {
    return get(`/order/status/${data.outTradeNo}`)
}

// 登录/注册
export const login = (data = {}) => {
    return post(`/auth/login`, data)
}
// 登录/注册
export const logout = (data = {}) => {
    return del(`/auth/logout`, data)
}
// 获取验证码
export const getCode = (data) => {
    return get(`/auth/verify_by_phone`, data)
}
// 获取验证码
export const getEmailCode = (email = '') => {
    return post(`/auth/send/email`, {}, email)
}
// 设置密码
export const resetPassword = (data = {}) => {
    return post(`/auth/password/reset`, data)
}
// 设置密码
export const resetEmailPassword = (data = {}) => {
    return post(`/auth/email/password/reset`, data)
}
// 博主详情
export const getBloggerDetail = (data = {}) => {
    return post(`/BloggerRanking/BloggerSearchById`, {}, data)
}
// 博主排行榜
export const getBloggerRanking = (data = {}) => {
    return post(`/BloggerRanking/search`, data)
}
// 博主详情页博主全部视频
export const getBloggerAllVideo = (data = {}) => {
    return post(`/BloggerRanking/detail`, data)
}
// 博主详情页是否收藏开关
export const getIsBloggerCollect = (data = {}) => {
    return post(`/BloggerCollect/detail/collect`, {}, data)
}
// 博主收藏列表
export const getBloggerCollectList = (data = {}) => {
    return post(`/BloggerCollect/search`, data)
}
// 博主添加收藏
export const addBloggerCollect = (data = {}) => {
    return post(`/BloggerCollect/insert`, data)
}
// 博主删除收藏
export const delBloggerCollect = (data = {}) => {
    return post(`/BloggerCollect/delete`, {}, data)
}
// 博主对比
export const bloggerCompare = (data = {}) => {
    return post(`/BloggerCompareHistory/compare`, data)
}
// 对比历史
export const compareHistory = (data = {}) => {
    return post(`/BloggerCompareHistory/search`, data)
}
// 模糊检索博主
export const bloggerSearch = (data = {}) => {
    return post(`/BloggerRanking/BloggerSearch`, {}, data)
}
// 添加PK
export const insertPk = (data = {}) => {
    return post(`/BloggerCompareInventory/insert`, data)
}
// 添加PK
export const insertHistory = (data = {}) => {
    return post(`/BloggerCompareInventory/search`, data)
}
// delPK
export const delAllPK = (data = {}) => {
    return post(`/BloggerCompareInventory/deleteAll`, data)
}
// delPKItem
export const delItemPK = (data = {}) => {
    return post(`BloggerCompareInventory/delete`, {}, data)
}
// 视频排行列表
export const getVideoRanking = (data = {}) => {
    return post(`/VideoRanking/search`, data)
}
// 视频详情
export const getVideoDetail = (data = {}) => {
    return post(`/VideoRanking/videoSearchById`, {}, data)
}
// 视频详情视频列表
export const detailVideoList = (data = {}) => {
    return post(`/BloggerRanking/detail`, data)
}
// 视频是否收藏
export const getIsVideoCollect = (data = {}) => {
    return post(`/VideoCollect/detail/collect`, {}, data)
}
// 视频添加收藏
export const addVideoCollect = (data = {}) => {
    return post(`/VideoCollect/insert`, data)
}
// 视频删除收藏
export const delVideoCollect = (data = {}) => {
    return post(`/VideoCollect/delete`, {}, data)
}
// 发布需求
export const marketSubmit = (data = {}) => {
    return post(`/market/merchant/apply`, data)
}
// 需求详情
export const marketDetail = (data = {}) => {
    return get(`/market/merchant/list/detail`, data)
}
// 需求详情
export const marketBloggerDetail = (data = {}) => {
    return post(`/market/blogger/list/detail`, data)
}
// 视频
export const videoRanking = (data = {}) => {
    return post(`/VideoCollect/search`, data)
}
// 话题是否收藏
export const getIsTipCollect = (data = {}) => {
    return post(`/TitleCollect/detail/collect`, {}, data)
}
// 话题添加收藏
export const addTipCollect = (data = {}) => {
    return post(`/TitleCollect/insert`, data)
}
// 话题删除收藏
export const delTipCollect = (data = {}) => {
    return post(`/TitleCollect/delete`, {}, data)
}
// 话题收藏列表
export const getTipCollect = (data = {}) => {
    return post(`/TitleCollect/search`, data)
}
// 我的钱包
export const getWallet = (data = {}) => {
    return post(`/user/wallet`, data)
}
// 新增提现申请
export const addWalletInsert = (data = {}) => {
    return post(`/withdrawallog/insert`, data)
}
// 查询提现日志
export const getWithdrawallog = (data = {}) => {
    return post(`/withdrawallog/search`, data)
}
// 钱包记录
export const getRecordsWallet = (data = {}) => {
    return post(`/market/blogger/wallet/records`, data)
}
// 导航搜索
export const getSearch = (data = {}) => {
    return post(`/BloggerRanking/searchBox/search`, data)
}
// 新增订阅话题
export const addSubscribeTitle = (data = {}) => {
    return post(`/SubscribeTitle/insert`, data)
}
// 删除订阅话题
export const delSubscribeTitle = (data = {}) => {
    return post(`/SubscribeTitle/delete`, {}, data)
}
// 获取订阅话题列表
export const getSubscribeTitle = (data = {}) => {
    return post(`/SubscribeTitle/search`, data)
}
// 分类
export const getCargo = (data = {}) => {
    return get(`/dict/cargo`, data)
}
// 签约
export const siginSub = (data = {}) => {
    return post(`/redAccount/submit`, data)
}
// 获取tiktok二维码接口
export const getQrCode = (data = {}) => {
    return post(`/auth/tiktok/getCode`, data)
}
// 获取tiktok二维码接口
export const checkCode = (data = {}) => {
    return post(`/auth/tiktok/checkCode`, {}, data)
}
// 红人列表
export const getRedAccount = (data = {}) => {
    return post(`/user/redAccount/search`, data)
}
// 视频概述
export const getRedSummary = (data = {}) => {
    return post(`/redAccount/search/video/summary`, {}, data)
}
// 视频概述
export const getRedAnalysis = (data = {}) => {
    return post(`/redAccount/video/analysis`, data)
}
// 视频概述
export const getRedFansAnalysis = (data = {}) => {
    return post(`/redAccount/fans/analysis`, data)
}
// 视频概述
export const getRedWordAnalysis = (data = {}) => {
    return post(`/redAccount/title/analysis`, {}, data)
}
// 红人搜索
export const getRedkeyword = (data = {}) => {
    return post(`/redAccount/fuzzy/search`, {}, data)
}
// 验证邮箱
export const emailBool = (data = {}) => {
    return post(`/redAccount/check/email`, data)
}
// 所有国家列表
export const getCountry = (data = {}) => {
    return post(`/country/page`, data)
}
// 所有国家列表
export const getRequirement = (data = {}) => {
    return post(`/requirement/search`, data)
}
// 商家完善信息
export const infoSub = (data = {}) => {
    return post(`/enterprise/set/info`, data)
}
// 主要市场
export const marketSearch = (data = {}) => {
    return post(`/enterprise/market/search`, data)
}
// 判断商家是否完善信息
export const getSign = (data = {}) => {
    return post(`/enterprise/get/sign`, data)
}
// 判断红人签约
export const getRedSign = (data = {}) => {
    return post(`/redAccount/get/email/sign`, data)
}
// 红人个人中心信息设置
export const redSetup = (data = {}) => {
    return post(`/account/redAccount/setup`, data)
}
// 获取当前红人的信用积分
export const getRedCredit = (data = {}) => {
    return post(`/user/credit/search`, data)
}
// 用户信用等级评估记录
export const getCreditLog = (data = {}) => {
    return post(`/user/growthValue/search/log`, data)
}
// 用户信用积分明细
export const getCreditDetail = (data = {}) => {
    return post(`/user/growthValue/search/detail`, data)
}
// 个人中心消息通知
export const getNewsList = (data = {}) => {
    return post(`/information/search/type`, {}, data)
}
// 个人中心消息通知未读数量
export const getNewsTotal = (data = {}) => {
    return post(`/information/search`, {}, data)
}
// 合作库搜索
export const getMarketBlogger = (data = {}) => {
    return post(`/market/blogger/list`, data)
}
// 签约信息查看
export const getMCNSign = (data = {}) => {
    return get(`/mcn/contract`, data)
}
// 签约信息设置
export const getMcnContract = (data = {}) => {
    return post(`/mcn/contract`, data)
}
// 随机生成机构码
export const getMcn_code = (data = {}) => {
    return get(`/mcn/mcn_code`, data)
}
// 根据机构码查看名称
export const getMcn_codeName = (data = {}) => {
    return get(`/mcn/name/${data}`)
}
// MCN收益记录
export const getMcn_wallet = (data = {}) => {
    return post(`/mcn/wallet/records`, data)
}
// 提现记录查询
export const getMcn_withdrawallog = (data = {}) => {
    return post(`/withdrawallog/search`, data)
}
// MCN提现
export const getMcn_insert = (data = {}) => {
    return post(`/withdrawallog/insert`, data)
}
// MCN红人库查询
export const getMcn_redAccount = (data = {}) => {
    return post(`/redAccount/search`, data)
}
// MCN添加红人
export const getMcn_addredAccount = (data = {}) => {
    return post(`/mcn/creator/add`, data)
}
// 红人添加(即邀请)记录
export const getMcn_invitation = (data = {}) => {
    return post(`/mcn/creator/invitation`, data)
}
// 红人接受邀请
export const getMcn_mark = (data = {}) => {
    return post(`/mcn/creator/invitation/mark`, data)
}
// MCN红人当前收益总额
export const getMcn_redtotal = (data = {}) => {
    return post(`/market/blogger/wallet/total`, data)
}
// 商家合作付款记录
export const getBusiness_merchantRecord = (data = {}) => {
    return post(`/market/merchant/wallet/records`, data)
}
// 商家充值记录
export const getBusiness_rechargeRecord = (data = {}) => {
    return post(`/wallet/recharge/records`, data)
}
// 退款记录查询
export const getBusiness_withdrawallog = (data = {}) => {
    return post(`/withdrawallog/search`, data)
}
// 商家充值
export const setBusiness_recharge = (data = {}) => {
    return post(`/order/recharge`, data)
}
// 查询商家支付状态
export const getBusinessOrderStatus = (data) => {
    return get(`/order/status/${data}`)
}
// MCn需求大厅
export const getMcnMarket = (data) => {
    return post(`/market/mcn/list`, data)
}
// MCn
export const marketMcnApply = (data) => {
    return post(`/market/mcn/apply`, data)
}
// MCn合作库
export const getMcnApplicationsLists = (data) => {
    return post(`/market/mcn/applications`, data)
}
// MCn需求详情
export const marketMcnDetail = (data) => {
    return post(`/market/mcn/list/detail`, data)
}
// 解绑红人
export const delMcnRed = (data) => {
    return post(`/mcn/creator/del`, data)
}
// 商家总支出
export const pTotal = (data) => {
    return post(`/market/merchant/wallet/total`, data)
}
// 商品所有分类
export const getCategory = (data) => {
    return get(`/product/category/all`, data)
}
// 商品分类属性
export const getCategoryAttribute = (data) => {
    return get(`/product/category/attribute?categoryId=${data}`)
}
// 商品提示
export const getProductConfig = (data) => {
    return get(`/product/config`)
}
// 发布商品，草稿
export const postProduct = (data) => {
    return post(`/product/my/item`, data)
}
// 有小店的国家列表
export const getShopCountry = (data) => {
    return tkGet(`/country/has/shop`)
}
// 小店列表
export const getShopList = (data, datas) => {
    return tkPost(`/shop/list`, datas, data)
}
// 小店详情
export const getShopDetail= (data) => {
    return get(`/shop/${data}`)
}
// 小店趋势分析
export const getShopAnalysis= (data) => {
    return post(`/shop/data/analysis`, data)
}
// 小店详情商品列表
export const getShopProductList = (data, datas) => {
    return tkPost(`/product/ranking/list`, datas, data)
}
// 小店关联的博主
export const getShopBloggerList = (shopId, data) => {
    return get(`/shop/blogger/list/${shopId}`, data)
}
// 收藏小店
export const shopCollect = (data) => {
    return get(`/shop/collect/${data}`)
}
// 收藏小店商品
export const shopProductCollect = (data) => {
    return get(`/product/ranking/collect/${data}`)
}
// 收藏小店列表
export const getShopCollectList = (data, datas) => {
    return post(`/shop/collect/list`, datas, data)
}
// 获取当前用户的地址列表
export const getCurrentUserAddressList = (data) => {
    return get(`/redAccount/getCurrentUserAddressList`)
}
// 新增地址
export const addAddress = (data) => {
    return post(`/redAccount/addAddress`, data)
}
// 追踪码生成
export const generateCode = (data) => {
    return get(`/distributionTracking/generateCode`)
}
// 追踪码记录
export const doRecord = (data) => {
    return post(`/distributionTracking/doRecord`, data)
}


//个人中心
export const getUserInfo = (data = {}) => {
    return get(`/auth/user/info`, data)
}

export const setUpUser = (data = {}) => {
    return post(`/account/user/setup`, data)
}

//企业认证
export const searchEnterprise = (data = {}) => {
    return post(`/enterprise/search`, data)
}

export const commitEnterprise = (data = {}) => {
    return post(`/enterprise/commit`, data)
}

//需求大厅
export const getMarketlist = (data = {}) => {
    return post(`/market/blogger/list`, data)
}

//合作库
export const getMerchantList = (data = {}) => {
    return post(`/market/merchant/list`, data)
}

export const payCooperate = (data = {}) => {
    return post(`/market/merchant/cooperate/pay`, data)
}

export const marketApply = (data = {}) => {
    return post(`/market/blogger/apply`, data)
}
export const marketBloggerApply = (data = {}) => {
    return post(`/market/merchant/list/blogger_applications`, data)
}
export const marketSuccess = (data = {}) => {
    return post(`/market/merchant/success`, data)
}

export const getBloggerApplicationsLists = (data = {}) => {
    return post(`/market/blogger/applications`, data)
}

export const applicationsBlogger = (data = {}) => {
    return post(`/market/merchant/list/blogger_applications`, data)
}

export const pay_info = (data = {}) => {
    return post(`/market/merchant/cooperate/pay_info`, data)
}

export const bloggerConfirm = (data = {}) => {
    return post(`/market/blogger/confirm`, data)
}

export const merchantSuccess = (data = {}) => {
    return post(`/market/merchant/success`, data)
}

export const searchIndustry = (data = {}) => {
    return post(`/industry/search`, data)
}

export const informationSearch = (data = {}) => {
    return post(`/information/search `, data)
}

export const updateStatus = (data = {}) => {
    return post(`/information/updateStatus`, data)
}

export const updateStatusBatch = (data = {}) => {
    return post(`/information/updateStatusBatch`, data)
}

export const susdcny = (data = {}) => {
    return post(`/market/ex/susdcny`, data)
}

export const customMember = (data = {}) => {
    return post(`/account/custom/member`, data)
}

export const redAccount = (data = {}) => {
    return post(`/redAccount/search/detail?redAccountId=${data.id}`)
}
export const redSAccount = (data = {}) => {
    return post(`/redAccount/search/detail/uid?uid=${data.id}`)
}

export const refuse = (data = {}) => {
    return post(`/market/blogger/refuse`, data)
}

export const searchinterests = (data = {}) => {
    return post(`/interests/search`, data)
}

export const getMembershipLogs = (data = {}) => {
    return post(`/membershipUser/getLogs`, data)
}

export const searchVideos = (data = {}) => {
    return post(`/interests/search/videos`, data, data)
}

export const paymentRecord = (data = {}) => {
    return post(`/account/query/paymentRecord`, data, data)
}
//话题收藏
export const TitleCollect = (data = {}) => {
    return post(`/TitleCollect/insert`, data)
}

export const deleteTitle = (data = {}) => {
    return post(`/TitleCollect/delete/titleId`, {}, data)
}

//MCN接口
//MCN信息查看
export const getMCNcontract = (data = {}) => {
    return get(`/mcn/contract`, data)
}

//MCN信息修改
export const editMCNcontract = (data = {}) => {
    return post(`/mcn/contract`, data)
}

//MCN数据统计
export const getMCNStatInfo = (data = {}) => {
    return post(`/mcn/stat/info`, data)
}

//获取样品收货地址
export const getMCNaddress = (data = {}) => {
    return get(`/mcn/address`, data)
}

//设置样品收货地址
export const setMCNaddress = (data = {}) => {
    return post(`/mcn/address`, data)
}

//MCN密码修改
export const resetMCNPassword = (data = {}) => {
    return post(`/auth/email/password/reset`, data)
}

//商品
//商品类型列表
export const getCategoryAll = (data = {}) => {
    return get(`/product/category/all`)
}

//我的店铺商品_我的商品管理列表
export const getMyProduct = (data = {}) => {
    return post(`/product/my/list`, data)
}

//商品列表
export const getCategoryList = (data = {}) => {
    return post(`/product/list`, data)
}

//商品列表
export const getCollectList = (data = {}) => {
    return post(`/product/collect/list`, data)
}

//获取商品信息_商家&红人&MCN机构查看商品详情页
export const getProduct = (data = {}) => {
    return get(`/product/${data.productId}`)
}

//收藏商品
export const collectProduct = (data = {}) => {
    return post(`/product/collect/${data.productId}`)
}

//取消收藏商品
export const cancelCollectProduct = (data = {}) => {
    return del(`/product/collect/${data.productId}`)
}


//带货商品
export const collectDistribute = (data = {}) => {
    return post(`/product/distribute/${data.productId}`)
}

//取消带货商品
export const cancelDistributeProduct = (data = {}) => {
    return del(`/product/distribute/${data.productId}`)
}

// 已带货的商品
export const getDistributeGoodsList = (data = {}) => {
    return post(`/product/distribute/list`,data)
}

export const delMyGoods = (data = {}) => {
    return del(`/product/my/item`, data)
}

export const editMyGoods = (data = {}) => {
    return post(`/product/my/item`, data)
}

//我的店铺商品_我的商品列表上架下架删除_操作状态
export const productStatus = (data = {}) => {
    return post(`/product/my/item/status`, data)
}

export const getProductDetail = (data = {}) => {
    return get(`/product/merchant/${data.userId}`, data)
}

export const productMstatus = (data = {}) => {
    return post(`/product/my/item/mstatus`, data)
}

export const variation_listSubmit = (data = {}) => {
    return post(`/product/my/item/variation_list`, data)
}


export const product_my_stat = (data = {}) => {
    return get(`/product/my/stat`, data)
}

// 商品排行榜列表
export const getGoodsRankingList = (datas, data) => {
    return tkPost(`/product/ranking/list`, datas, data)
}
// 商品排行榜收藏列表
export const getGoodsRankingCollect = (datas,data) => {
    return post(`/product/ranking/collect/list`, datas, data)
}
// 获取商品排行详情
export const getGoodsRankingDetail = (data = {}) => {
    return get(`/product/ranking/${data.id}`,{},{productId:data.productId})
}
// 获取商品排行详情基础分析
export const getGoodsRankingAnalysis = (data = {}) => {
    return post(`/product/ranking/data/analysis`,data)
}
// 获取商品排行关联博主信息
export const getGoodsRelatedBlogger = (data = {}) => {
    return post(`/product/ranking/blogger/data`,data)
}
// 商品排行榜详情关联博主列表
export const getBloggerRankingList = (data = {}) => {
    let url = `${data.id}?current=${data.current}&size=${data.size}`
    let key = data.descColumn ? 'descColumn' : 'ascColumn'
    let value = data.descColumn || data.ascColumn
    if(value) url += `&${key}=${value}`
    return get(`/product/ranking/shop/list/${url}`)
}
// 商品排行榜收藏
export const collectGoodsRanking = (data = {}) => {
    return get(`/product/ranking/collect/${data.id}`)
}

// 获取红人优秀作品
export const getNiceWorkList = (data = {}) => {
    return get(`/redAccount/getNiceWorkList?uid=${data.uid}`)
}
// 红人优秀作品上传视频
export const niceWorkUpload = (data = {}) => {
    return get(`/redAccount/niceWorkUpload`)
}
// 红人优秀作品上传
export const commitNiceWork = (data = {}) => {
    return post(`/redAccount/commitNiceWork`,data)
}
// 红人优秀作品修改
export const editNiceWork = (data = {}) => {
    return post(`/redAccount/editNiceWork`,data)
}
// 红人优秀作品删除
export const deleteNiceWork = (data = {}) => {
    return del(`/redAccount/deleteNiceWork`,data.ids)
}
// 获取红人的收件地址列表
export const getRedAddressList = (data = {}) => {
    return get(`redAccount/getAddressList?uid=${data.uid}`)
}
// 新增红人的收件地址
export const addRedAddress = (data = {}) => {
    return post(`/redAccount/addAddress`,data)
}
// 修改红人的收件地址
export const editRedAddress = (data = {}) => {
    return post(`/redAccount/updateAddress`,data)
}
// 删除红人的收件地址
export const delRedAddress = (data = {}) => {
    return del(`/redAccount/delAddress?id=${data.id}`)
}
// 商品排行榜详情收藏博主
export const collectGoodsRelatedBlogger = (data = {}) => {
    return get(`/shop/collect/${data.id}`)
}
// 音乐排行榜列表
export const getMusicRanking = (data = {},params = {}) => {
    let url = `?current=${data.current}&size=${data.size}`
    let key = data.descColumn ? 'descColumn' : 'ascColumn'
    let value = data.descColumn || data.ascColumn
    if(value) url += `&${key}=${value}`

    return post(`/music/ranking/list${url}`,params)
}
// 音乐排行榜详情
export const getMusicRankingDetail = (data = {}) => {
    return get(`/music/ranking/${data.id}`)
}
// 收藏单首音乐
export const collectMusicRanking = (data = {}) => {
    return get(`/music/ranking/collect/${data.id}`)
}
// 音乐详情趋势数据
export const getMusicRankingAnalysis = (data = {}) => {
    return post(`/music/ranking/data/analysis`,data)
}
// 音乐详情视频列表数据
export const getMusicRankingVideoList = (data = {}) => {
    let url = `${data.id}?current=${data.current}&size=${data.size}`
    let key = data.descColumn ? 'descColumn' : 'ascColumn'
    let value = data.descColumn || data.ascColumn
    if(value) url += `&${key}=${value}`
    return get(`/music/ranking/video/list/${url}`)
}












// =========================供应链=++++++++++++++++++++++
// http://192.168.1.140:9800/api/auth/register
// 注册
export const register = (data = {}) => {
    return post(`/auth/register_by_phone`,data)
}

// 获取国家列表
export const getCountryPage = (data = {}) => {
    return post(`/country/page`,data)
}

// 新增修改商品信息
export const handleProduct = (data = {}) => {
    return post(`/product/item`,data)
}
// 获取商品信息
export const getSupplyProductDetail = (data = {}) => {
    return get(`/product/item?productId=${data.productId}`)
}

// 复制商品信息
export const copySupplyProductDetail = (data = {}) => {
    return post(`/product/item/copy?productId=${data.productId}`,data)
}

// 获取供应商订单列表
export const getSupplyOrderList = (data = {}) => {
    return post(`/order_ref_tk_shop/page`,data)
}

// 获取供应商订单的列表的数值信息
export const getSupplyOrderData = (data = {}) => {
    return get(`/order_ref_tk_shop/my/stat`)
}

// 删除供应商订单
export const deleteSupplyOrder = (data = {}) => {
    return del(`/order_ref_tk_shop?id=${data.id}`,data)
}

// 获取供应商订单的数据图表信息
export const getSupplyOrderTrend = (data = {}) => {
    return post(`/order_ref_tk_shop/trend`,data)
}

// 获取供应商店铺日志信息
export const getSupplyShopLog = (data = {}) => {
    return post(`/oper_log/page`,data)
}

// 获取供应商日志模块分类
export const getSupplyShopModule = (data = {}) => {
    return get(`/oper_log/modules`,data)
}

// 获取供应商首页的数值信息
export const getSupplyIndexData = (data = {}) => {
    return get(`/product/my/stat_home`,data)
}

// 获取供应商首页的公告信息
export const getSupplyIndexAnnouncement = (data = {}) => {
    return post(`/announcement/page`,data)
}

// 登录
export const shopLogin = (data = {}) => {
    return post(`/auth/login_by_phone`,data)
}
// 忘记密码
export const forget = (data = {}) => {
    return post(`/auth/reset_password_by_phone`,data)
}
// 店铺类型列表
export const shopType = (data = {}) => {
    return get(`/dict/shopType`,data)
}
// 经营规模列表
export const businessScale = (data = {}) => {
    return get(`/dict/businessScale`,data)
}
// 地区接口
export const addressOption = (data = {}) => {
    return get(`/dict/area`,data)
}
// 完善资料
export const resource = (data = {}) => {
    return post(`/user/resource/`,data)
}
// 用户信息
export const infoP = (data = {}) => {
    return get(`/auth/user/info`,data)
}
// 店铺信息
export const infoShop = (data = {}) => {
    return get(`/shop/info`,data)
}
// 公司信息
export const infoCompany = (data = {}) => {
    return get(`/shop/company/info`,data)
}
// 店铺信息修改
export const putShop = (data = {}) => {
    return put(`/shop/`,data)
}
// 修改店铺头像
export const addAvatar = (data = {}) => {
    return post(`/shop/upload/avatar`,data)
}
// 修改支付账号
export const setPay = (data = {}) => {
    return post(`/shop/setting/pay`,data)
}
// 账号列表
export const accountList = (data = {}) => {
    return get(`/shop/account/list`,data)
}
// 添加子账号
export const subAccount = (data = {}) => {
    return post(`/shop/add/subAccount`,data)
}
// 删除账号
export const delAccount = (data = {}) => {
    return del(`/shop/account/${data}`,data)
}
// 角色列表
export const getRole = (data = {}) => {
    return get(`/dict/role/list`,data)
}
// 结算金额
export const getSettlementAmount = (data = {}) => {
    return get(`/financial/settlement/amount`,data)
}
// 总金额统计
export const getAllAmount = (data = {}) => {
    return get(`/financial/amount`,data)
}
// 可提现金额 已提现金额
export const getWithdrawalAmount = (data = {}) => {
    return get(`/financial/withdrawal/amount`,data)
}
// 账单列表
export const getOrderList = (data = {}) => {
    return get(`/financial/order/list`,data)
}
// 提现列表
export const getWithdrawalList = (data = {}) => {
    return get(`/financial/withdrawal/list`,data)
}
// 提现
export const postWithdrawal = (data = {}) => {
    return post(`/financial/withdrawal`,data)
}

// 获取品购星选列表
export const getStarList = (data = {}) => {
    let url = `?current=${data.current}&size=${data.size}&title=${data.title}`
    return post(`product/old/list${url}`,data)
}
