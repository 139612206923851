import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import { Modal, Input, message, Select, Checkbox, Image, Spin, Pagination, DatePicker, Popconfirm, Steps  } from 'antd';
import * as Api from '../../../service/https';
import '../../../asset/css/MyGoods.scss'
import intl from 'react-intl-universal';
import store from '../../../utils/redux/index'
import { debounce } from '../../../utils/debounce'
import {
    deleteSupplyOrder,
    editMyGoods,
    getSupplyOrderData,
    getSupplyOrderList,
    getSupplyOrderTrend
} from "../../../service/https";
import openNotificationWithIcon from "../../../components/Notification";
import { color } from 'echarts';
import moment from 'moment';
const { RangePicker } = DatePicker;

const { Option } = Select

const MyOrder = (props) => {
    const OrderStatusText = {1:'未支付',2:'待发货',3:'已发货',4:'已完成',5:'已取消'}
    const OrderStatusColor = {1:'#F7385B',2:'#F7385B',3:'#F7385B',4:'#40D782',5:'#F7385B'}
    const [active_index, active_indexSet] = useState(props.location?.state?.orderStatus || -1)
    const [searchParams, searchParamsSet] = useState({
        size: 10,
        timeFilter:{start:null,end:null},
        timeFilterFieldFlag: 1,
        sortFlag: 1,
        orderByFlag: 1
    })
    const [daySelectIndex,setDaySelectIndex] = useState(0)
    const [daySelect,setDaySelect] = useState([
        { label:'所有时间', start:null,end:null},
        { label:'近7天', start:moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 7).format('yyyy-MM-DD HH:mm:ss'),end:moment(new Date()).format('yyyy-MM-DD HH:mm:ss')},
        { label:'近15天', start:moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 15).format('yyyy-MM-DD HH:mm:ss'),end:moment(new Date()).format('yyyy-MM-DD HH:mm:ss')},
        { label:'近30天', start:moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 30).format('yyyy-MM-DD HH:mm:ss'),end:moment(new Date()).format('yyyy-MM-DD HH:mm:ss')},
    ])
    const [orderSortIndex,setOrderSortIndex] = useState(1)
    const [list, listSet] = useState([])
    const [selectList, selectListSet] = useState([])
    const [id_list, id_listSet] = useState([])
    const [open_status, open_statusSet] = useState(-1)
    const [isSpinning, isSpinningSet] = useState(false)
    const [focus, focusSet] = useState(-1)
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [sortFansIndex, setRangeFansNumDto] = useState(-1)
    const [total, totalSet] = useState(0)
    const [goodsType, goodsTypeSet] = useState([])
    const [sortNumList, setItems] = useState([
        { start: 0, key: '<', end: 100 },
        { start: 100, key: '~', end: 200 },
        { start: 200, key: '~', end: 500 },
        { start: 500, key: '~', end: 1000 },
        { start: 1000, key: '<', end: 0 },
        { start: '', key: '~', end: '' },
    ]);

    useEffect(e => {
        getCategoryAll()
    }, [])

    useEffect(() => {
        getOrderList()
    }, [searchParams, pageIndex, active_index])

    const getCategoryAll = e => {
        Api.getCategoryAll().then(res => {
            if (res.code === 200) {
                goodsTypeSet(res.data)
            }
        })
    }

    const deleteOrder = id => {
        Api.deleteSupplyOrder({ id:id }).then(res => {
            if (res.code === 200) {
                message.success("删除成功~")
                getOrderList()
            }
        })
    }

    const getOrderList = e => {
        setLoading(true)
        let data = {orderStatus: active_index, current: pageIndex,}
        Api.getSupplyOrderList({ ...data, ...searchParams }).then(res => {
            if (res.code === 200) {
                let t = res.data.records.map(item => {
                    return item.tkOrderId
                })
                let vmList = res.data.records
                setIndeterminate(false)
                setCheckAll(false)
                selectListSet([])
                setCheckedList([])
                id_listSet(t)
                listSet(pageIndex <= 1 ? vmList : [...vmList,...list])
                totalSet(res.data.total)
                setLoading(false)
                getMyOrderTrend()
            }
        })
    }

    const [stat_total, stat_totalSet] = useState({})

    const getMyOrderTrend = e => {
        Api.getSupplyOrderData().then(res => {
            if (res.code === 200) {
                let data = Object.assign({},res.data)
                data.total= 0
                for(let key in res.data) data.total += Number(res.data[key])
                stat_totalSet(data)
            }
        })
    }

    const tabsChange = e => {
        setPageIndex(1)
        open_statusSet(-1)
        active_indexSet(e)
    }

    const searchChange = (e, id) => {
        let t = JSON.parse(JSON.stringify(searchParams))
        if(id === 'orderByFlag'){
            setOrderSortIndex(e)
            if(e == 1 || e == 2) {
                t['orderByFlag'] = 1
                t['sortFlag'] = e
            } else {
                t['orderByFlag'] = 2
            }
            if(e == 3) t['sortFlag'] = 2
            if(e == 4) t['sortFlag'] = 1
        } else if(id === 'timeFilter'){
            setDaySelectIndex(e)
            t['timeFilter'] = daySelect[e]
        } else {
            t[id] = e
        }
        searchParamsSet(t)
    }

    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const onChange = (e) => {
        console.log(e)
        setCheckedList(e);
        setIndeterminate(!!e?.length && e?.length < list?.length);
        setCheckAll(e?.length === list?.length);
        let s = []
        list.map((item, index) => {
            if (e.indexOf(item?.tkOrderId) !== -1) {
                s.push(item.tkOrderId)
            }
        })
        selectListSet(s)
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? id_list : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const copyFun = (value, e) => {
        e.stopPropagation()
        // for ie
        if (window.clipboardData) {
            window.clipboardData.clearData();
            window.clipboardData.setData('text', value);
            message.success("复制成功~")
        }
        // for modern browser
        else if (document.execCommand) {
            var element = document.createElement('SPAN');
            element.textContent = value;
            document.body.appendChild(element);
            if (document.selection) {
                var range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                var range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            document.execCommand('copy');
            element.remove ? element.remove() : element.removeNode(true);
            message.success("复制成功~")
        } else {
            message.warn("您所用的浏览器不支持快捷复制，请手动复制！")
        }
    }

    const [goods_var, goods_varSet] = useState([])
    const [commissionIndex, setCommissionIndex] = useState(-1)
    const [commission, setCommission] = useState('')

    const openEditCommission = (e, item, index) => {
        // setCommission(item?.commissionPercent || 0)
        let t = JSON.parse(JSON.stringify(list))
        t[index].showCommissionInput = true
        listSet(t)
        setCommissionIndex(index)
    }

    const changeCommission = (value,index) => {
        // setCommission(value < 0 ? 0 : value > 80 ? 80 : value)
        let t = JSON.parse(JSON.stringify(list))
        t[index].vmCommissionPercent = value < 0 ? 0 : value > 80 ? 80 : value
        listSet(t)
    }

    const blurCommissionInput = (e,item,index) => {
        let t = JSON.parse(JSON.stringify(list))
        t[index].vmCommissionPercent = item.vmCommissionPercent < 0 ? 0 : Number(item.vmCommissionPercent)
        listSet(t)
        setCommissionIndex(index)
    }

    const openStatusChange = (e, item) => {
        focusSet(-1)
        if (e === open_status) {
            open_statusSet(-1)
        } else {
            let t = item?.variationList
            goods_varSet(t)
            open_statusSet(e)
            isSpinningSet(true)
            setTimeout(() => {
                isSpinningSet(false)
            }, 1000);
        }
    }

    const goods_varChange = (e, i, id) => {
        let t = JSON.parse(JSON.stringify(goods_var))
        t[i][id] = e
        goods_varSet(t)
    }

    const save = e => {
        let data = {
            productId: e,
            variationList: goods_var
        }
        let isFloors = true
        for (let index = 0; index < goods_var?.length; index++) {
            const element = goods_var[index];
            if(element.price ==0 || element.quantity ==0){
                isFloors = false
                break
            }

        }
        if(JSON.stringify(goods_var).indexOf(('""')) === -1 && isFloors){
            Api.variation_listSubmit(data).then(res => {
                if (res.code === 200) {
                    if (pageIndex === 1) {
                        getOrderList()
                    } else {
                        setPageIndex(1)
                    }
                    open_statusSet(-1)
                    message.success('保存成功！')
                }
            })
        }else{
            message.info('不能为空或0！')
        }
    }


    const delMyGoods = (e, status) => {
        let data = {
            productIds: e || checkedList,
            orderStatus: status
        }
        Api.delMyGoods(data).then(res => {
            if (res.code === 200) {
                if (pageIndex === 1) {
                    getOrderList()
                } else {
                    setPageIndex(1)
                }
            }
        })
    }

    const toDetail = (item, e) => {
        e.stopPropagation()
        window.open(`/index/GoodsDetail/${item?.productId}`)
    }

    const initEditCommission = () =>{
        let t = JSON.parse(JSON.stringify(list))
        t[commissionIndex].showCommissionInput = false
        t[commissionIndex].vmCommissionPercent = t[commissionIndex]?.commissionPercent
        listSet(t)
        setCommissionIndex(-1)
    }

    useEffect(()=>{
        if(commissionIndex >= 0) {
            window.addEventListener('click', initEditCommission)
        }
        return ()=>{
            window.removeEventListener('click',initEditCommission)
        }
    },[commissionIndex])


    const changeDateTime = value=>{
        var start = '',end = ''
        console.log(value);
        if(value){
            start = moment(value[0]).format("YYYY-MM-DD HH:mm:ss")
            end = moment(value[1]).format("YYYY-MM-DD HH:mm:ss")
            searchChange({start,end},'timeFilter')
        }else{
            searchChange({start: null,end: null},'timeFilter')
        }
    }

    // 面单弹窗
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalTextValue, modalTextValueSet] = useState('')
    const [printImg, setPrintImg] = useState(null)
    const openImgModal = (img,text) => {
        setPrintImg(img)
        modalTextValueSet(text)
        if(img) setIsModalVisible(true)
    }

    // 物流弹窗信息
    const [courierModal, courierModalSet] = useState(false)
    const openCourierModal = item => {
        courierModalSet(true)
    }

    return (
        <div id='MyGoods'>
            <div style={{ display: 'flex', alignItems:'center' }}>
                <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
                style={{width:'4px'}}/>
                <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>订单管理</div>
            </div>
            <div className='tabs'>
                <div className={active_index === -1 ? 'active' : ''} onClick={e => tabsChange(-1)}>全部({stat_total?.total || 0})</div>
                <div className={active_index === 1 ? 'active' : ''} onClick={e => tabsChange(1)}>未支付({stat_total?.totalNoPay || 0})</div>
                <div className={active_index === 2 ? 'active' : ''} onClick={e => tabsChange(2)}>待发货({stat_total?.totalToShip || 0})</div>
                <div className={active_index === 3 ? 'active' : ''} onClick={e => tabsChange(3)}>已发货({stat_total?.totalShipped || 0})</div>
                <div className={active_index === 4 ? 'active' : ''} onClick={e => tabsChange(4)}>完成({stat_total?.totalCompletion || 0})</div>
                <div className={active_index === 5 ? 'active' : ''} onClick={e => tabsChange(5)}>已取消({stat_total?.totalCanceled || 0})</div>
            </div>
            <div id='fans' className='search'>
                <div style={{position:'relative'}}>
                    <div id='login'>
                        <Input onChange={e => searchChange(e.target.value, 'tkOrderId')} value={searchParams.tkOrderId}
                        style={{ width: '313px', height: '30px', paddingLeft:'100px' }} placeholder={'按照ID搜索'}
                        suffix={<img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/search.png'} style={{ width: '20px', cursor:'pointer' }} alt="" />} allowClear />
                    </div>
                    <Select
                        aria-readonly={true}
                        defaultValue="订单ID"
                        style={{
                            width: 100, position:'absolute', top:0,left:0 ,zIndex:11
                        }}
                        bordered={false}
                        options={[
                            {
                            value: '订单ID',
                            label: '订单ID',
                            },
                            {
                            value: '达人ID',
                            label: '达人ID',
                            },
                        ]}
                    />
                </div>
                <div id='Myorder' style={{position:'relative', marginLeft:'10px'}}>
                    <RangePicker onChange={changeDateTime} style={{paddingLeft:'130px'}}
                    disabledDate={e=>e.format() > moment().startOf('day').format()}
                    />
                    <Select
                        onChange={e => searchChange(e, 'timeFilterFieldFlag')}
                        defaultValue="订单创建时间"
                        style={{
                            width: 130, position:'absolute', top:0,left:0 ,zIndex:11
                        }}
                        bordered={false}
                        options={[
                            {
                            value: 1,
                            label: '订单创建时间',
                            },
                            {
                            value: 2,
                            label: '订单支付时间',
                            },
                        ]}
                    />
                </div>
                <div style={{ margin: '0 5px' }}></div>
                <Select onChange={e => searchChange(e, 'timeFilter')} value={daySelectIndex} placeholder={'选择时间'} style={{ width: 120 }}>
                    {
                        daySelect?.map((item,index) =>{
                            return <Option key={index} value={index}>{item.label}</Option>
                        })
                    }
                </Select>
                {/*<div>{moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 7).format('yyyy-MM-DD')}</div>*/}
                <div style={{ margin: '0 5px' }}></div>
                <Select onChange={e => searchChange(e, 'orderByFlag')} value={orderSortIndex} placeholder={'创建时间由近到远'} style={{ width: 170 }}>
                    <Option value={1}>创建时间由近到远</Option>
                    <Option value={2}>创建时间由远到近</Option>
                    <Option value={3}>支付时间由近到远</Option>
                    <Option value={4}>支付时间由远到近</Option>
                </Select>
                {/* <div style={{ color: '#F7385B', backgroundColor: '#FFEEF1', border: '1px solid #F7385B', padding: '4px 22px', borderRadius: '5px', marginLeft: '30px', cursor: 'pointer' }} onClick={e => clear()}>重置</div> */}
            </div>
            <div className='goods_main'>
                <div style={{padding:'5px 0'}}>
                    {/* <div style={{ marginRight: '30px' }}>已选：<span style={{color:'#F7385B'}}>{checkedList?.length}</span></div>
                    <div className={checkedList?.length > 0 ? 'btn_active' : 'btn'} style={{ marginRight: '16px', display: active_index === -1 || active_index === 2 ? '' : 'none' }} onClick={e => checkedList?.length > 0 ? productMstatus('', 1) : ''}>上架</div>
                    <div className={checkedList?.length > 0 ? 'btn_active' : 'btn'} style={{ marginRight: '16px', display: active_index === -1 || active_index === 1 ? '' : 'none' }} onClick={e => checkedList?.length > 0 ? productMstatus('', 2) : ''}>下架</div>
                    <div className={checkedList?.length > 0 ? 'btn_active' : 'btn'} style={{ marginRight: '16px', display: active_index === 4 ? 'none' : '' }} onClick={e => checkedList?.length > 0 ? active_index === 3 ? delMyGoods('', 4) : productMstatus('', 4) : ''}>删除</div>
                    <div className={checkedList?.length > 0 ? 'btn_active' : 'btn'} style={{ marginRight: '16px', display: active_index === 4 ? '' : 'none' }} onClick={e => checkedList?.length > 0 ? productMstatus('', 2) : ''}>恢复</div> */}
                </div>
                <div style={{fontWeight:500, fontSize:'13px', color:'#666'}}>
                    <Checkbox style={{ width: '18px' }} indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}></Checkbox>
                    <div style={{ width: '300px', marginLeft: '15px' }}>订单ID</div>
                    {/* <div style={{ width: '80px'}}>SKU</div> */}
                    <div style={{ width: '140px' }}>订单状态</div>
                    <div style={{ width: '160px' }}>分销达人</div>
                    <div style={{ width: '100px' }}>支付</div>
                    <div style={{ width: '200px' }}>物流信息</div>
                    <div style={{ }}>操作</div>
                </div>
            </div>
            <Spin tip="数据加载中..." spinning={loading}>
                <Checkbox.Group value={checkedList} style={{ width: '100%' }} onChange={onChange}>
                    <div className='list'>
                        {
                            list?.length > 0 ?
                                list.map((item, index) => {
                                    return (
                                        <div key={index} style={{background:'#FFF',marginBottom:14}}>
                                            <div className='flex-acjb' style={{ borderBottom:'1px solid #EEEEEE', paddingBottom:20}}>
                                                <div style={{fontSize:'12px', color:'#888888', lineHeight:'14px'}}>
                                                    ID: {item?.tkOrderId}
                                                    <img  onClick={e => copyFun(item?.tkOrderId, e)}  src='https://zc-tk.oss-cn-beijing.aliyuncs.com/fuzhi.png' style={{ width: 9, height: 10, marginLeft: 5, cursor: 'pointer', marginTop: -1 }} />
                                                </div>
                                                <div>下单时间：{item?.tkOrderCreatedTime}</div>
                                            </div>
                                            <div style={{fontWeight:500,width:'100%', height:'100px',display:'flex', alignItems:'center'}}>
                                                <Checkbox style={{ width: '18px' }}  value={item?.tkOrderId} ></Checkbox>
                                                <div style={{ width: '300px',  marginLeft: '15px',  display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                                    <Image src={item?.productImgUrls[0]}
                                                           style={{width:'60px', height:'60px', borderRadius:'4px',display:'block'}}
                                                           fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}/>
                                                    <div style={{ margin:'0 15px'}}>
                                                        <div className='over-word' style={{fontWeight:600, lineHeight:'15px'}}>{item?.productName}</div>
                                                    </div>
                                                </div>
                                                <div style={{ width: '140px' }}><span style={{background:'#DBFFEB', fontSize:'12px', borderRadius:'6px',
                                                    color: OrderStatusColor[item?.orderStatus], padding:'1px 6px'}}>{OrderStatusText[item?.orderStatus]}</span></div>
                                                <div style={{ width: '160px' }}>
                                                    <div style={{display:'flex', alignItems:'center'}}>
                                                        <Image src=''
                                                               style={{width:'40px', height:'40px', borderRadius:'20px'}}
                                                               fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}/>
                                                        <div style={{marginLeft:'5px'}}>
                                                            <div className='over1'></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: '100px' }}>${item?.subtotal}</div>
                                                <div style={{ width: '200px' }}></div>
                                                <div style={{ }}>
                                                    { item?.tkMiandanImg ? <div style={{cursor:'pointer',color:'#1B9EFF'}} onClick={()=>{openImgModal(item?.tkMiandanImg,'商品面单')}}>打印面单</div> : '' }
                                                    { item?.orderStatus == 2 &&  item?.tkWuliuImg ? <Popconfirm
                                                            title="确认发货?"
                                                            onConfirm={getOrderList}
                                                            okText="确定"
                                                            cancelText="取消">
                                                         <div style={{cursor:'pointer',color:'#1B9EFF'}}>安排发货</div>
                                                    </Popconfirm> : ''}
                                                    { item?.orderStatus == 3 && item?.tkWuliuImg ? <div style={{cursor:'pointer',color:'#1B9EFF'}} onClick={()=>{openImgModal(item?.tkWuliuImg,'物流面单')}}>补物流单</div> : '' }
                                                    { item?.orderStatus == 3 && item?.tkWuliuImg ? <div style={{cursor:'pointer',color:'#1B9EFF'}} onClick={()=>{openCourierModal(item)}} >查看物流</div> : '' }
                                                    { item?.orderStatus == 5 ? <Popconfirm
                                                            title="确认删除订单?"
                                                            onConfirm={()=>{deleteOrder(item?.id)}}
                                                            okText="确定"
                                                            cancelText="取消">
                                                            <div style={{cursor:'pointer',color:'#F7385B'}}>取消</div>
                                                    </Popconfirm>: '' }
                                                </div>
                                        {/* <div style={{ width: '100px'}}><div style={{cursor:'pointer'}}>打印面单</div> <div style={{cursor:'pointer'}}>补物流单</div> <div style={{cursor:'pointer'}}>取消</div></div>
                <div style={{ width: '100px' }}><div style={{cursor:'pointer'}}>查看物流</div></div> */}
                                    </div>
                                  </div>
                                )
                                })
                                :
                                <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                    <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                    <div style={{ marginTop: '23px' }}>暂无数据</div>
                                </div>
                        }
                    </div>
                </Checkbox.Group>
            </Spin>
            <Pagination style={{ float: 'right', margin: '20px 0' }} current={pageIndex} total={total} showTotal={(total) => `共 ${total} 条`} onChange={e => { setPageIndex(e) }} />
            <Modal style={{textAlign:'center'}} visible={isModalVisible} centered={true} footer={null} onCancel={e => { setIsModalVisible(false); setPrintImg(null); }}
                   getContainer={() => document.getElementById('MyGoods')}>
                <div style={{marginBottom:20,fontSize:20}}>{modalTextValue}</div>
                <Image style={{margin:'0 auto', display:'block',textAlign:'center'}} width={460} height={460} preview={false} src={printImg} alt=""></Image>
            </Modal>
            <Modal style={{textAlign:'center'}} visible={courierModal} centered={true} footer={null} onCancel={e => { courierModalSet(false)}}
                   getContainer={() => document.getElementById('MyGoods')}>
                <div style={{marginBottom:20,fontSize:20}}>物流信息</div>
                <Steps
                    direction="vertical"
                    size="small"
                    current={1}
                    items={[
                        { title: 'Finished', description:'' },
                        {
                            title: 'In Progress',
                            description:'',
                        },
                        {
                            title: 'Waiting',
                            description:'',
                        },
                    ]}
                />
            </Modal>
        </div>
    )
}
export default withRouter(MyOrder);
