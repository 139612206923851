import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import '../../../asset/css/BloggerRanking.scss'
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import { Select, Checkbox, List, notification, Image, message, Divider, Spin, Input, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import domtoimage from 'dom-to-image';
import intl from 'react-intl-universal';
import { saveAs } from 'file-saver';
import PkModel from '../../../components/PkModel'
import ShareBloggerModel from '../../../components/Share/ShareBloggerModel'
import * as Api from '../../../service/https'
import toKMB from '../../../utils/KMB'
import {debounce} from '../../../utils/debounce'
// import geolocation from '../../../utils/geolocation'

const { Option } = Select;

const Sensation = (props) => {
    const ScrollContainer = useRef(null);
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [widths, setWidths] = useState(0)
    useEffect(() => {
        // console.log(props)
        // insertHistory()
        // window.scrollTo(0, 0)
        getCargo()
        getCountry()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
            notification.destroy()
        }
    }, [])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(height)
        let num = state.collapsed + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }
    useEffect(() => {
        heightFun()
    }, [state.collapsed])

    
    const [tabIndex, setTabIndex] = useState(-1)
    const [tabShow, setTabShow] = useState(false)
    const changeTab = (index) => {
        setPageIndex(1)
        setTabIndex(index)
    }
    const [classList, setClassList] = useState([])
    const [classIndex, setClassIndex] = useState(0)
    const [classShow, setClassShow] = useState(false)
    const sortClassIndex = (index) => {
        setPageIndex(1)
        setClassIndex(index)
    }
    const getCargo = ()=>{
        Api.getCargo().then(res=>{
            if(res.code == 200){
                let all = res.data
                all.unshift({id: 0, desc: '全部', goodsEnglishName: 'All'})
                setClassList(all)
            }
        })
    }

    const [geolocation, setGeolocation] = useState([])
    const getCountry = ()=>{
        Api.getCountry().then(res=>{
            if(res.code == 200){
                let all = res.data.records
                setGeolocation(all)
            }
        })
    }


    const [isBig, setBig] = useState(false)
    const changeBig = () => {

    }


    const [sortNumList, setItems] = useState([
        {start: 0, key: '<', end: 10000},
        {start: 10000, key: '~', end: 100000},
        {start: 100000, key: '~', end: 500000},
        {start: 500000, key: '~', end: 1000000},
        {start: 1000000, key: '~', end: 5000000},
        {start: 5000000, key: '~', end: 10000000},
        {start: 10000000, key: '~', end: 50000000},
        {start: 50000000, key: '<', end: 0},
        {start: '', key: '~', end: ''},
        {start: '', key: '~', end: ''},
        {start: '', key: '~', end: ''}
    ]);
    const [selectConfirmStatus, setSelectConfirmStatus] = useState(1)
    const [sortFansIndex, setRangeFansNumDto] = useState(-1)
    const [sortFollowIndex, setRangeFollowNumDto] = useState(-1)
    const [sortPlayIndex, setRangePlayNumDto] = useState(-1)
    const changeMinInput = (index, value)=>{
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].start = value ? parseInt(value) : ''
        setItems(v)
    }
    const changeMaxInput = (index, value)=>{
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].end = value ? parseInt(value) : ''
        setItems(v)
    }
    const changeRangeFansNumDto = (index)=>{
        setPageIndex(1)
        setRangeFansNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[8] = {start: '', key: '~', end: ''}
            setItems(v)
        } else {
            setSelectConfirmStatus(selectConfirmStatus == 1 ? 2 : 1)
        }
    }
    const changeRangeFolloNumDto = (index)=>{
        setPageIndex(1)
        setRangeFollowNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[9] = {start: '', key: '~', end: ''}
            setItems(v)
        } else {
            setSelectConfirmStatus(selectConfirmStatus == 1 ? 2 : 1)
        }
    }
    const changeRangePlayNumDto = (index)=>{
        setPageIndex(1)
        setRangePlayNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[10] = {start: '', key: '~', end: ''}
            setItems(v)
        } else {
            setSelectConfirmStatus(selectConfirmStatus == 1 ? 2 : 1)
        }
    }


    const [sortPriceList, setPriceList] = useState([
        {start: 0, key: '<', end: 100},
        {start: 100, key: '~', end: 1000},
        {start: 1000, key: '~', end: 5000},
        {start: 5000, key: '~', end: 10000},
        {start: 10000, key: '~', end: 50000},
        {start: 50000, key: '~', end: 100000},
        {start: 100000, key: '~', end: 500000},
        {start: 500000, key: '<', end: 0},
        {start: '', key: '~', end: ''},
        {start: '', key: '~', end: ''}
    ]);
    const [sortVideoIndex, setRangeVideoNumDto] = useState(-1)
    const [sortLiveIndex, setRangeLiveNumDto] = useState(-1)
    const changeMinPriceInput = (index, value)=>{
        let v = JSON.parse(JSON.stringify(sortPriceList))
        v[index].start = value ? parseInt(value) : ''
        setPriceList(v)
    }
    const changeMaxPriceInput = (index, value)=>{
        let v = JSON.parse(JSON.stringify(sortPriceList))
        v[index].end = value ? parseInt(value) : ''
        setPriceList(v)
    }
    const changeRangeVideoQuotationDto = (index)=>{
            setPageIndex(1)
            setRangeVideoNumDto(index == undefined ? -2 : index)
            if(index==undefined){
                let v = JSON.parse(JSON.stringify(sortPriceList))
                v[8] = {start: '', key: '~', end: ''}
                setPriceList(v)
            } else {
                setSelectConfirmStatus(selectConfirmStatus == 1 ? 2 : 1)
            }
    }
    const changeRangeLiveBroadcastQuotationDto = (index)=>{
            setPageIndex(1)
            setRangeLiveNumDto(index == undefined ? -2 : index)
            if (index == undefined) {
                let v = JSON.parse(JSON.stringify(sortPriceList))
                v[9] = {start: '', key: '~', end: ''}
                setPriceList(v)
            } else {
                setSelectConfirmStatus(selectConfirmStatus == 1 ? 2 : 1)
            }
    }


    const [classify, setClassify] = useState([]);
    const [enterprise, setEnterprise] = useState(false);
    const [social, setSocial] = useState(false);
    const [link, setLink] = useState(false);
    const [contact, setContact] = useState(false);

    const checkedC = (bool, num) => {
        setPageIndex(1)
        if (bool) {
            let list = JSON.parse(JSON.stringify(classify))
            list.push(num)
            setClassify(list)
        } else {
            let list = JSON.parse(JSON.stringify(classify))
            for (var i = 0; i < list.length; i++) {
                var ele = list[i];
                var index = list.indexOf(ele);
                if (index > -1) {
                    list.splice(index, 1);
                }
            }
            setClassify(list)
        }
    }
    const isCheckeds = (num) => {
        let list = JSON.parse(JSON.stringify(classify))
        return list.indexOf(num) > -1
    }


    const [sortList, setSortList] = useState([intl.get('BloggerRanking').FansNum, intl.get('BloggerRanking').NumberVideos,
    intl.get('BloggerRanking').LikesNum, intl.get('BloggerRanking').AveragePlayback, intl.get('Index')['视频制作单价'], intl.get('Index')['直播合作单价']]);
    const [sortIndex, setSortIndex] = useState(0);
    const sortMethod = (index) => {
        setPageIndex(1)
        setSortIndex(index)
    }

    useEffect(() => {
        loadMoreData()
    }, [sortIndex, classify, classIndex, tabIndex])
    useEffect(() => {
        if (sortFansIndex != -1 || sortFollowIndex != -1 || sortPlayIndex != -1 || sortVideoIndex != -1 || sortLiveIndex != -1) loadMoreData()
    }, [sortFansIndex, sortFollowIndex, sortPlayIndex, sortVideoIndex, sortLiveIndex, selectConfirmStatus])





    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            nationality: tabIndex == -1 ? '' : geolocation[tabIndex].nameCn,
            cargoClassification: classIndex == 0 ? '' :classList[classIndex].desc,
            redAccountSortRuler: sortIndex + 1,
            current: pageIndex, size: 20,
            rangeFansNumDto: sortFansIndex == (-2 || -1) ? { "start": 0, "end": 0 } : sortFansIndex >=0 ? {start: sortNumList[sortFansIndex]?.start || 0, key: sortNumList[sortFansIndex]?.key, end: sortNumList[sortFansIndex]?.end || 0} : null,
            rangeFollowNumDto: sortFollowIndex == (-2 || -1) ? { "start": 0, "end": 0 } : sortFollowIndex >=0 ? {start: sortNumList[sortFollowIndex]?.start || 0, key: sortNumList[sortFollowIndex]?.key, end: sortNumList[sortFollowIndex]?.end || 0} : null,
            rangePlayNumDto: sortPlayIndex == (-2 || -1) ? { "start": 0, "end": 0 } : sortPlayIndex >=0 ? {start: sortNumList[sortPlayIndex]?.start || 0, key: sortNumList[sortPlayIndex]?.key, end: sortNumList[sortPlayIndex]?.end || 0} : null,
            rangeVideoQuotationDtoDollar: sortVideoIndex == (-2 || -1) ? { "start": 0, "end": 0 } : sortPriceList[sortVideoIndex] ? {start: sortPriceList[sortVideoIndex]?.start || 0, key: sortPriceList[sortVideoIndex]?.key, end: sortPriceList[sortVideoIndex]?.end || 0} : null,
            rangeLiveBroadcastQuotationDtoDollar: sortLiveIndex == (-2 || -1) ? { "start": 0, "end": 0 } : sortPriceList[sortLiveIndex] ? {start: sortPriceList[sortLiveIndex]?.start || 0, key: sortPriceList[sortLiveIndex]?.key, end: sortPriceList[sortLiveIndex]?.end || 0} : null,
            classify: classify
        }
        Api.getRedAccount(obj).then(res => {
            // console.log(res)
            setLoading(false);
            if (res.code == 200) {
                setTotal(res.data.total)
                setData(pageIndex == 1 ? res.data.records : [...data, ...res.data.records]);
                setPageIndex(pageIndex + 1)
            }
        }).catch(() => {
            setLoading(false);
        });
    };


    const [isMouse, setIsMouse] = useState(false)
    const [clientXS, setClientXS] = useState()
    const [clientXE, setClientXE] = useState()
    const handleMouseDown = e=>{
        // console.log(e.clientX)
        setIsMouse(true)
        setClientXS(e.clientX)
    }
    const handleMouseMove = e=>{
        // console.log(e.clientX)
        if(isMouse)setClientXE(e.clientX)
    }
    const handleMouseUp = e=>{
        // console.log(e.clientX)
        setIsMouse(false)
    }
    useEffect(()=>{
        if(isMouse)ScrollContainer.current.scrollLeft += -(clientXE - clientXS)
    },[clientXE])



    // 列表
    const SmallDivLeft = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '100px', display: 'flex', alignItems: 'center',
            // cursor: 'pointer'
        }}
            >

            <div style={{ width: '80px', paddingLeft: '19px' }}>
                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt=""
                    style={{}} /> :
                    <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                }
            </div>
            <div style={{ width:'calc(100% - 80px)', height:'100%', display: 'flex', alignItems: 'center'}}>
                <Image onClick={e => e.stopPropagation()} src={item.avatarMedium} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                    style={{ width: '60px', height: '60px', borderRadius: '30px', marginRight: '16px' }} />
                <div onClick={() => detail(item, index)} style={{ width:'calc(100% - 76px)',color: '#888888' }}>
                    <div style={{
                        color: '#333333', fontWeight: 'bold', fontSize: '16px',
                        display: 'flex', alignItems: 'center', marginTop:'-8px'
                    }}>
                        <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.nickname}</div>
                        {item.verified ? <img style={{ marginLeft: '3px', width: '14px', height: '14px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} /> : ''}
                        {item.bioLink != null ? <img style={{ marginLeft: '3px', width: '13px', height: '13px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lianjie.png'} /> : ''}
                    </div>
                    <div style={{fontSize:'12px', lineHeight:'15px', position:'relative', top:'-2px'}}>TikTok: {item.tiktok}</div>
                    <div className='over1' style={{fontSize:'12px', color:'#F7385B', display:'flex', marginTop:'3px'}}>
                        {
                            item.cargoClassifications?.map((val,index)=>{
                                return <span key={index} style={{background:'#FFE7E9', padding:'1px 10px', borderRadius:'15px', marginRight:'6px'}}>{val.val}</span>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    }
    // 列表
    const SmallDivCenter = (item, index) => {
        return <div key={index} style={{
            minWidth:'100%', width: '1100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent:'space-between',
            backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6', position:'relative'
        }}
            // onClick={() => detail(item, index)}
            onMouseDown={handleMouseDown} 
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            >
            {/* <div style={{ width: '100px', float:'left' }}>--</div> */}
            <div style={{ width: '150px', float:'left', display:'flex', alignItems:'center' }}>
                <img src={item.nationalFlag} style={{width:'14px', marginRight:'3px'}} />{lang=='zh'?item.nationality:item.nationalityEn}
            </div>
            <div style={{ width: '100px', float:'left' }}>${item.videoQuotationDollar}/60s</div>
            <div style={{ width: '100px', float:'left' }}>${item.liveBroadcastQuotationDollar}/h</div>
            <div style={{ width: '100px', float:'left' }}>{toKMB(item.followerCount, 2)}</div>
            <div style={{ width: '100px', float:'left' }}>{toKMB(item.videoCount, 2)}</div>
            <div style={{ width: '100px', float:'left' }}>{toKMB(item.ptvRatioSpeculate, 2)}</div>
            <div style={{ width: '100px', float:'left' }}>{toKMB(item.heartCount, 2)}</div>
            <div style={{ width: '100px', float:'left' }}>{item.htfRatio}</div>
            <div style={{ width: '200px', float:'left' }}>{item.mcnOrganizationName || '--'}</div>
        </div>
    }
    // 列表
    const SmallDivRight = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '100px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6', position:'relative'
        }}
            >
            
            <div style={{ width: '100%', height:'100%', display:'flex', alignItems:'center', paddingLeft:'15px' }}>
            {/* <div style={{ width: '100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center' }}> */}
                {/* <Tooltip placement="top" title={<span style={{fontSize:'12px'}}>添加PK</span>}>
                    <img onClick={(e) => isChecked(item) ? delPkItem(e, item, index) : addPK(e, item, index)}
                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pk' + (isChecked(item) ? 's' : '') + '.png'} 
                        style={{ marginRight: '9px', cursor:'pointer' }} alt="" />
                </Tooltip> */}
                {/* <Tooltip placement="top" title={<span style={{fontSize:'12px'}}>添加收藏</span>}>
                    <img onClick={(e) => isChecked(item) ? delPkItem(e, item, index) : addPK(e, item, index)}
                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc' + (isChecked(item) ? 's' : '') + '.png'} style={{ marginRight: '9px' }} alt="" />
                </Tooltip> */}
                <Tooltip placement="top" title={<span style={{fontSize:'12px'}}>向该博主发起合作需求</span>}>
                    <img onClick={(e) =>goToRelese(item)}
                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sqhz.png'} 
                        style={{width:'36px', marginRight: '9px', cursor:'pointer' }} alt="" />
                </Tooltip>
            </div>
        </div>
    }

    const goToRelese = (item) => {
        props.navigate('/index/releaseDemand', { state: {blogger: item} })
        // if(state.userInfo?.enterprise){
        //     props.navigate('/index/releaseDemand', { state: {blogger: item} })
        // }else{
        //     message.warn("请先进行企业认证！")
        //     props.navigate('/index/EnterpriseCertification')
        // }
    }
    

    const isChecked = (item) => {
        let bools = false
        for (let index = 0; index < imgList.length; index++) {
            const e = imgList[index]
            if (item.id == e.bloggerId) {
                bools = true
                break
            }
        }
        return bools
    }

    const detail = (item, index) => {
        // window.open(`/index/sensationDetail/${item.id}/${item.tiktok}`)
    }
    const addPK = (e, item, index) => {
        e.stopPropagation();
        if (imgList.length < 6) {
            let obj = {
                bloggerId: item.id, uniqueId: item.tiktok, nickname: item.nickname,
                avatarMedium: item.avatarMedium
            }
            Api.insertPk(obj).then(res => {
                if (res.code == 200) {
                    insertHistory()
                }
            })
        }
    }
    const delPkItem = (e, item) => {
        e.stopPropagation()
        let delIndex = -1
        for (let index = 0; index < imgList.length; index++) {
            const e = imgList[index]
            if (item.id == e.bloggerId) {
                delIndex = index
                break
            }
        }
        if (delIndex > -1) {
            Api.delItemPK({ inventoryId: imgList[delIndex].id }).then(res => {
                if (res.code == 200) {
                    insertHistory()
                }
            })
        }
    }





    // 生成图片自动下载为png格式（将dom转为二进制再编译下载）
    const [isShare, setIsShare] = useState(false)
    const getBlobPng = () => {
        setIsShare(!isShare)
    }



    // 获取PK
    const [imgList, setImgList] = useState([])
    const insertHistory = () => {
        Api.insertHistory().then(res => {
            if (res.code == 200) {
                notification.destroy()
                setImgList(res.data)
            }
        })
    }
    useEffect(() => {
        notification.destroy()
        if (imgList.length > 0) {
            openNotification()
        }
    }, [imgList])



    const [isPk, setIsPk] = useState(false)
    useEffect(() => {
        if (isShare) {
            notification.destroy()
        } else {
            if (imgList.length > 0)openNotification()
        }
    }, [isShare])
    useEffect(() => {
        if (imgList.length > 0 && !isPk) {
            openNotification()
        }
    }, [isPk])
    const openNotification = () => {
        notification.open({
            duration: null,
            getContainer: () => document.getElementById('BloggerRanking'),
            placement: 'bottomLeft',
            message: <div style={{ fontWeight: 500 }}>{intl.get('BloggerComparison').BloggerComparison} <span style={{ color: '#888888' }}>{imgList.length}/6</span> </div>,
            description: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                <div style={{ display: 'flex' }}>
                    {
                        imgList?.map((item, index) => {
                            return <img key={index} src={item.avatarMedium} alt=""
                                style={{
                                    width: '40px', height: '40px', borderRadius: '20px', marginRight: '-12px',
                                    border: '3px solid #FFF', position: 'relative', zIndex: 10 - index
                                }} />
                        })
                    }
                </div>
                <div style={{
                    width: '80px', height: '30px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                    borderRadius: '4px', cursor: 'pointer'
                }}
                    onClick={() => pkResult()}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pkdb.png'} alt=""
                        style={{ width: '14px', marginRight: '4px', }} />{intl.get('BloggerComparison').Contrast}
                </div>
            </div>,
            className: 'custom-class',
            style: {
                width: 350,
            },
            closeIcon: <div>{intl.get('BloggerComparison').Empty}</div>,
            onClose: () => {
                notification.destroy()
                Api.delAllPK().then(res => {
                    if (res.code == 200) {
                        insertHistory()
                    }
                })
            }
        });
    };

    const [pkResultList, setPkResultList] = useState([])
    useEffect(() => {
        if (pkResultList.length > 0) setIsPk(true)
    }, [pkResultList])
    const pkResult = () => {
        let obj = {
            bloggerList: imgList, type: 1
        }
        Api.bloggerCompare(obj).then(res => {
            if (res.code == 200) {
                setPkResultList(res.data)
            }
        })
    }





    return <div id="BloggerRanking" style={{ paddingBottom: '50px' }}>
        <div style={{ display: 'flex', alignItems:'center' }}>
            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
            style={{width:'4px'}}/>
            <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>{intl.get('Index')['红人库']}</div>
        </div>

        <div style={{
            width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '48px', paddingBottom: '25px',
            fontWeight: 500, marginTop:'12px'
        }}>
            {/* 国家 */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ width: state.language == 'zh' ? '102px' : '90px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{intl.get('BloggerRanking').Country}：</label>
                <div style={{
                    width: 'calc(100% - 70px)', height: tabShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',
                    overflow: tabShow ? '' : 'hidden'
                }}>
                    <span className='tabItem' style={{
                        padding: '6px 10px',
                        background: -1 == tabIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                        color: -1 == tabIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                        cursor: 'pointer'
                    }}
                                onClick={() => changeTab(-1)}>{lang=='zh'?'全部':'All'}</span>
                    {
                        geolocation?.map((item, index) => {
                            return <span key={index} className='tabItem' style={{
                                padding: '6px 10px',
                                background: index == tabIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                                color: index == tabIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                cursor: 'pointer'
                            }}
                                onClick={() => changeTab(index)}>{lang == 'zh' ?item.nameCn:item.nameEn}</span>
                        })
                    }
                </div>
                <div style={{ width: '45px' }}>
                    {!tabShow ?
                        <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => { setTabShow(true) }} />
                        :
                        <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => setTabShow(false)} />}
                </div>
            </div>
            {/* 带货分类 */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ width: state.language == 'zh' ? '102px' : '90px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{intl.get('Index')['带货分类']}：</label>
                <div style={{
                    width: 'calc(100% - 70px)', height: classShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',
                    overflow: classShow ? '' : 'hidden'
                }}>
                    {
                        classList?.map((item, index) => {
                            return <span key={index} className='tabItem' style={{
                                padding: '6px 10px',
                                background: index == classIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                                color: index == classIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                cursor: 'pointer'
                            }}
                                onClick={() => sortClassIndex(index)}>{lang == 'zh' ?item.desc:item.desc}</span>
                        })
                    }
                </div>
                <div style={{ width: '45px' }}>
                    {!classShow ?
                        <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => { setClassShow(true) }} />
                        :
                        <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => setClassShow(false)} />}
                </div>
            </div>

            {/* 筛选 */}
            <div style={{ width: '100%', marginTop: '20px', display: 'flex' }}>
                <label style={{ width: state.language == 'zh' ? '99px' : '100px', textAlign: 'right', paddingRight: state.language == 'zh' ? '28px' : '32px', marginTop:'5px' }}>
                    {intl.get('BloggerRanking').Screen}：</label>
                <div style={{width:state.language == 'zh'?'calc(100% - 90px)':'calc(100% - 100px)',  display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                    <div id='fans' style={{marginBottom:'15px'}}>
                        <span style={{marginRight:'10px'}}>{intl.get('BloggerRanking').FansNum}</span> 
                        <Select
                            onClear={e=>changeRangeFansNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[8].start}
                                            onChange={e=>changeMinInput(8, e.target.value.replace(/^\D*([0-9]\d*)?.*$/,'$1'))}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[8].end}
                                            onChange={e=>changeMaxInput(8, e.target.value.replace(/^\D*([0-9]\d*)?.*$/,'$1'))}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" />
                                        <span style={{border: sortNumList[8].start || sortNumList[8].end || (sortNumList[8].start>=0 && sortNumList[8].end > sortNumList[8].start) ?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[8].start || sortNumList[8].end || (sortNumList[8].start>=0 && sortNumList[8].end > sortNumList[8].start) ?'#FFF':'#CCCCCC',
                                            background: sortNumList[8].start || sortNumList[8].end || (sortNumList[8].start>=0 && sortNumList[8].end > sortNumList[8].start) ?'#F7385B':'#FFF', fontSize:'12px'}}
                                              onClick={()=> sortNumList[8].start || sortNumList[8].end || (sortNumList[8].start>=0 && sortNumList[8].end > sortNumList[8].start) ? changeRangeFansNumDto(8) : null}>{intl.get('Index')['确定']}</span>
                                    </div>
                                </>
                            )}
                            value={(sortFansIndex == -1||sortFansIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortFansIndex].start>0?toKMB(sortNumList[sortFansIndex].start):'') + ' '+sortNumList[sortFansIndex].key+' ' + (sortNumList[sortFansIndex].end>0?toKMB(sortNumList[sortFansIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginRight:'40px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangeFansNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                    <div id='fans' style={{marginBottom:'15px'}}>
                        <span style={{marginRight:'10px'}}>{intl.get('BloggerRanking').LikesNum}</span> 
                        <Select
                            onClear={e=>changeRangeFolloNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[9].start}
                                            onChange={e=>changeMinInput(9, e.target.value.replace(/^\D*([0-9]\d*)?.*$/,'$1'))}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[9].end} 
                                            onChange={e=>changeMaxInput(9, e.target.value.replace(/^\D*([0-9]\d*)?.*$/,'$1'))}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" />
                                        <span style={{border: sortNumList[9].start || sortNumList[9].end || (sortNumList[9].start>=0 && sortNumList[9].end > sortNumList[9].start) ?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[9].start || sortNumList[9].end || (sortNumList[9].start>=0 && sortNumList[9].end > sortNumList[9].start) ?'#FFF':'#CCCCCC',
                                            background: sortNumList[9].start || sortNumList[9].end || (sortNumList[9].start>=0 && sortNumList[9].end > sortNumList[9].start) ?'#F7385B':'#FFF', fontSize:'12px'}}
                                              onClick={()=> sortNumList[9].start || sortNumList[9].end || (sortNumList[9].start>=0 && sortNumList[9].end > sortNumList[9].start) ? changeRangeFolloNumDto(9) : null}>{intl.get('Index')['确定']}</span>
                                    </div>
                                </>
                            )}
                            value={(sortFollowIndex == -1||sortFollowIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortFollowIndex].start>0?toKMB(sortNumList[sortFollowIndex].start):'') + ' '+sortNumList[sortFollowIndex].key+' ' + (sortNumList[sortFollowIndex].end>0?toKMB(sortNumList[sortFollowIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginRight:'40px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangeFolloNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                    <div id='fans' style={{marginBottom:'15px'}}>
                        <span style={{marginRight:'10px'}}>{intl.get('BloggerRanking').AveragePlayback}</span> 
                        <Select
                            onClear={e=>changeRangePlayNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[10].start} 
                                            onChange={e=>changeMinInput(10, e.target.value.replace(/^\D*([0-9]\d*)?.*$/,'$1'))}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[10].end} 
                                            onChange={e=>changeMaxInput(10, e.target.value.replace(/^\D*([0-9]\d*)?.*$/,'$1'))}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" />
                                        <span style={{border: sortNumList[10].start || sortNumList[10].end || (sortNumList[10].start>=0 && sortNumList[10].end > sortNumList[10].start) ?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[10].start || sortNumList[10].end || (sortNumList[10].start>=0 && sortNumList[10].end > sortNumList[10].start) ?'#FFF':'#CCCCCC',
                                            background: sortNumList[10].start || sortNumList[10].end || (sortNumList[10].start>=0 && sortNumList[10].end > sortNumList[10].start) ?'#F7385B':'#FFF', fontSize:'12px'}}
                                              onClick={()=> sortNumList[10].start || sortNumList[10].end || (sortNumList[10].start>=0 && sortNumList[10].end > sortNumList[10].start) ? changeRangePlayNumDto(10) : null}>{intl.get('Index')['确定']}</span>
                                    </div>
                                </>
                            )}
                            value={(sortPlayIndex == -1||sortPlayIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortPlayIndex].start>0?toKMB(sortNumList[sortPlayIndex].start):'') + ' '+sortNumList[sortPlayIndex].key+' ' + (sortNumList[sortPlayIndex].end>0?toKMB(sortNumList[sortPlayIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginRight:'40px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangePlayNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                    <div id='fans' style={{marginBottom:'15px'}}>
                        <span style={{marginRight:'10px'}}>{intl.get('Index')['单个视频制作报价']}</span> 
                        <Select
                            onClear={e=>changeRangeVideoQuotationDto(undefined)}
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortPriceList[8].start} 
                                            onChange={e=>changeMinPriceInput(8, e.target.value.replace(/^\D*([0-9]\d*)?.*$/,'$1'))}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortPriceList[8].end} 
                                            onChange={e=>changeMaxPriceInput(8, e.target.value.replace(/^\D*([0-9]\d*)?.*$/,'$1'))}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" /> 
                                        <span style={{border: sortPriceList[8].start || sortPriceList[8].end || (sortPriceList[8].start>=0 && sortPriceList[8].end > sortPriceList[8].start) ?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortPriceList[8].start || sortPriceList[8].end || (sortPriceList[8].start>=0 && sortPriceList[8].end > sortPriceList[8].start) ?'#FFF':'#CCCCCC',
                                            background: sortPriceList[8].start || sortPriceList[8].end || (sortPriceList[8].start>=0 && sortPriceList[8].end > sortPriceList[8].start) ?'#F7385B':'#FFF', fontSize:'12px'}}
                                            onClick={()=> sortPriceList[8].start || sortPriceList[8].end || (sortPriceList[8].start>=0 && sortPriceList[8].end > sortPriceList[8].start) ? changeRangeVideoQuotationDto(8) : null}>{intl.get('Index')['确定']}</span>
                                    </div>
                                </>
                            )}
                            value={(sortVideoIndex == -1||sortVideoIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortPriceList[sortVideoIndex].start>0?toKMB(sortPriceList[sortVideoIndex].start):'') + ' '+sortPriceList[sortVideoIndex].key+' ' + (sortPriceList[sortVideoIndex].end>0?toKMB(sortPriceList[sortVideoIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortPriceList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangeVideoQuotationDto(index)}>{item.start>0?'$'+toKMB(item.start):''} {item.key} {item.end>0?'$'+toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                        <span style={{color:'#CCCCCC', marginLeft:'11px', marginRight:'40px'}}>/60s</span>
                    </div>
                    <div id='fans' style={{marginBottom:'15px'}}>
                        <span style={{marginRight:'10px'}}>{intl.get('Index')['单场直播合作报价']}</span> 
                        <Select
                            onClear={e=>changeRangeLiveBroadcastQuotationDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortPriceList[9].start} 
                                            onChange={e=>changeMinPriceInput(9, e.target.value.replace(/^\D*([0-9]\d*)?.*$/,'$1'))}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortPriceList[9].end} 
                                            onChange={e=>changeMaxPriceInput(9, e.target.value.replace(/^\D*([0-9]\d*)?.*$/,'$1'))}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" />
                                        <span style={{border: sortPriceList[9].start || sortPriceList[9].end || (sortPriceList[9].start>=0 && sortPriceList[9].end > sortPriceList[9].start) ?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortPriceList[9].start || sortPriceList[9].end || (sortPriceList[9].start>=0 && sortPriceList[9].end > sortPriceList[9].start) ?'#FFF':'#CCCCCC',
                                            background: sortPriceList[9].start || sortPriceList[9].end || (sortPriceList[9].start>=0 && sortPriceList[9].end > sortPriceList[9].start) ?'#F7385B':'#FFF', fontSize:'12px'}}
                                              onClick={()=> sortPriceList[9].start || sortPriceList[9].end || (sortPriceList[9].start>=0 && sortPriceList[9].end > sortPriceList[9].start) ? changeRangeLiveBroadcastQuotationDto(9) : null}>{intl.get('Index')['确定']}</span>
                                    </div>
                                </>
                            )}
                            value={(sortLiveIndex == -1||sortLiveIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortPriceList[sortLiveIndex].start>0?toKMB(sortPriceList[sortLiveIndex].start):'') + ' '+sortPriceList[sortLiveIndex].key+' ' + (sortPriceList[sortLiveIndex].end>0?toKMB(sortPriceList[sortLiveIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortPriceList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangeLiveBroadcastQuotationDto(index)}>{item.start>0? '$' + toKMB(item.start):''} {item.key} {item.end>0? '$' + toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                        <span style={{color:'#CCCCCC', marginLeft:'11px', marginRight:'40px'}}>/1h</span>
                    </div>
                </div>
            </div>

            {/* 分类 */}
            <div style={{ width: '100%', marginTop: '19px', display: 'flex' }}>
                <label style={{
                    width: state.language == 'zh' ? '108px' : '100px', textAlign: 'right',
                    position: 'relative', left: '-33px'
                }}>{intl.get('BloggerRanking').Classification}：</label>
                <div style={{ width: state.language == 'zh' ? 'calc(100% - 70px)' : 'calc(100% - 90px)', display: 'flex', flexFlow: 'wrap' }}>
                    <div style={{ marginRight: '50px', marginBottom: '10px' }}>
                        <Checkbox onChange={e => checkedC(e.target.checked, 1)} className='active'>
                            <span style={{ color: isCheckeds(1) ? '#F7385B' : '' }}>{intl.get('Index')['有认证']}</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} alt=""
                                style={{ width: '14px', marginLeft: '3px', marginTop: '-2px' }} />
                        </Checkbox>
                    </div>
                    {/* <div style={{marginRight:'50px'}}>
                        <Checkbox onChange={e=>setEnterprise(e.target.checked)} className='active'>
                            <span style={{color: enterprise?'#F7385B':''}}>{intl.get('BloggerRanking').EnterpriseCertification}</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/renrenzheng.png'} alt="" 
                                style={{width:'14px', marginLeft:'3px', marginTop:'-2px'}}/>
                        </Checkbox>
                    </div>
                    <div style={{marginRight:'50px'}}>
                        <Checkbox onChange={e=>setSocial(e.target.checked)} className='active'>
                            <span style={{color: social?'#F7385B':''}}>{intl.get('BloggerRanking').AocialAccount}</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shejiao.png'} alt="" 
                                style={{width:'15px', marginLeft:'3px', marginTop:'-2px'}}/>
                        </Checkbox>
                    </div> */}
                    <div style={{ marginRight: '50px' }}>
                        <Checkbox onChange={e => checkedC(e.target.checked, 2)} className='active'>
                            <span style={{ color: isCheckeds(2) ? '#F7385B' : '' }}>{intl.get('BloggerRanking').PageLinks}</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lianjie.png'} alt=""
                                style={{ width: '13px', marginLeft: '3px', marginTop: '-2px' }} />
                        </Checkbox>
                    </div>
                    <div style={{ marginRight: '50px' }}>
                        <Checkbox onChange={e => checkedC(e.target.checked, 3)} className='active'>
                            <span style={{ color: isCheckeds(3) ? '#F7385B' : '' }}>有MCN机构</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} alt=""
                                style={{ width: '13px', marginLeft: '3px', marginTop: '-2px' }} />
                        </Checkbox>
                    </div>
                    {/* <div>
                        <Checkbox onChange={e=>setContact(e.target.checked)} className='active'>
                            <span style={{color: contact?'#F7385B':''}}>{intl.get('BloggerRanking').ContactInformation}</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/phone.png'} alt="" 
                                style={{width:'12px', marginLeft:'3px', marginTop:'-2px'}}/>
                        </Checkbox>
                    </div> */}
                </div>
            </div>
        </div>

        {/* 排序 */}
        <div style={{
            width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '47px',
            paddingRight: '24px'
        }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {
                    sortList?.map((item, index) => {
                        return <div key={index} className='active' style={{
                            display: 'flex', alignItems: 'center', marginRight: '36px',
                            color: sortIndex == index ? '#F7385B' : '#888888', cursor: 'pointer'
                        }}
                            onClick={() => sortMethod(index)}>
                            {item}
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index ? 's' : '') + '.png'} alt=""
                                style={{ marginLeft: '6px', marginTop: '2px' }} />
                        </div>
                    })
                }
            </div>
        </div>

        {/* 列表 */}
        <div style={{
            width: '100%', backgroundColor: '#FFFFFF', padding: '24px 24px 24px 30px',
            marginTop: '10px'
        }}>
            <Spin tip="数据加载中..." spinning={loading}>
            {data.length > 0 ? 
                <div style={{width:'100%', display:'flex'}}>
                    <div style={{width:'400px'}}>
                        <div style={{paddingRight:'10px', backgroundColor: '#F6F6F6'}}>
                            <div style={{
                                width: '100%', height: '50px', display: 'flex', alignItems: 'center',
                                fontWeight: 500, boxShadow: ScrollContainer?.current?.scrollLeft==0?'':'5px 0px 10px -5px #DFDFDF'
                            }}>
                                <div style={{ width: '83px', paddingLeft: '19px' }}>{intl.get('BloggerRanking').Ranking}</div>
                                <div style={{ paddingLeft: '14px' }}>{intl.get('Index')['红人']}</div>
                            </div>
                        </div>
                        <InfiniteScroll
                            dataLength={data.length} scrollThreshold={'20px'}
                            next={loadMoreData}
                            hasMore={data.length < total}
                            // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                            endMessage={state.userInfo?.userType == 1 && state.userInfo?.memberDetail?.memberType === '领航版' ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider> : ''}
                            scrollableTarget="scrollableDiv"
                        >
                            <List
                                dataSource={data} grid={{ gutter: 0, column: 1 }}
                                renderItem={(item, index) => 
                                <div key={index} style={{ paddingRight:'10px', backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6', }}>
                                    <div style={{ boxShadow: ScrollContainer?.current?.scrollLeft==0?'':'5px 0px 10px -5px #DFDFDF' }}>
                                        {SmallDivLeft(item, index)}
                                    </div>
                                </div> }
                            />
                        </InfiniteScroll>
                    </div>
                    <div ref={ScrollContainer} className={'leftRight'} style={{width:'calc(100% - 400px - 150px)', overflowX:'auto', userSelect:'none'}}>
                        <div style={{
                            minWidth:'100%', width: '1100px', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                            fontWeight: 500, justifyContent:'space-between', cursor:'col-resize'
                        }}>
                            {/* <div style={{ width: '100px', paddingRight: '10px' }}>{intl.get('Index')['信用']}</div> */}
                            <div style={{ width: '150px', paddingRight: '10px' }}>{intl.get('Index')['国籍']}</div>
                            <div style={{ width: '100px', paddingRight: '10px' }}>{intl.get('Index')['视频单价']}</div>
                            <div style={{ width: '100px', paddingRight: '10px' }}>{intl.get('Index')['直播单价']}</div>
                            <div style={{ width: '100px', paddingRight: '10px' }}>{intl.get('BloggerRanking').FansNum}</div>
                            <div style={{ width: '100px', paddingRight: '10px' }}>{intl.get('BloggerRanking').NumberVideos}</div>
                            <div style={{ width: '100px', paddingRight: '10px' }}>{intl.get('BloggerRanking').AveragePlayback}</div>
                            <div style={{ width: '100px', paddingRight: '10px' }}>{intl.get('BloggerRanking').LikesNum}</div>
                            <div style={{ width: '100px', paddingRight: '10px' }}>{intl.get('BloggerRanking').AveragePpowderRatio}</div>
                            <div style={{ width: '200px', paddingRight: '10px' }}>MCN</div>
                        </div>
                        <List
                            dataSource={data} grid={{ gutter: 0, column: 1 }}
                            renderItem={(item, index) =>
                            <div key={index} style={{ cursor:'col-resize' }}>
                                {SmallDivCenter(item, index)}
                            </div> }
                        />
                    </div>
                    <div style={{width:'150px', boxShadow: ScrollContainer?.current?.scrollLeft<401?'-5px 0px 10px -5px #DFDFDF':''}}>
                        <div style={{
                            width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                            fontWeight: 500
                        }}>
                            <div style={{ width: '', paddingLeft:'15px' }}>{intl.get('BloggerRanking').Operation}</div>
                        </div>
                        <List
                            dataSource={data} grid={{ gutter: 0, column: 1 }}
                            renderItem={(item, index) => 
                            <div key={index} style={{ boxShadow: ScrollContainer?.current?.scrollLeft<401?'-5px 0px 10px -5px #DFDFDF':'' }}>
                                {SmallDivRight(item, index)}
                            </div>}
                        />
                    </div>
                </div>
             : 
                <div style={{width:'345px', height:'200px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{intl.get('Common')['暂无数据']}</div>
            </div>}
            </Spin>
            {
                state.userInfo?.userType == 1 && state.userInfo?.memberStatus != 0 ?
                    state.userInfo?.memberDetail?.memberType === '起航版' ?
                        <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                style={{ width: '24px', marginRight: '7px' }} />
                            {intl.get('TopicList')['起航版员目前仅支持查看前200条数据']}
                            {/* <span style={{ color: '#F7385B', cursor: 'pointer' }}>{intl.get('BloggerRanking').OpenMember}</span>
                            {intl.get('BloggerRanking').ViewMoreData} */}
                        </div>
                        :
                        state.userInfo?.memberDetail?.memberType === '远航版' ?
                            <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                    style={{ width: '24px', marginRight: '7px' }} />
                                {intl.get('TopicList')['远航版员目前仅支持查看前500条数据']}
                                {/* <span style={{ color: '#F7385B', cursor: 'pointer' }}>{intl.get('BloggerRanking').OpenMember}</span>
                                {intl.get('BloggerRanking').ViewMoreData} */}
                            </div>
                            :
                            ""
                    :
                    state.userInfo?.userType == 1 ?
                        <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                style={{ width: '24px', marginRight: '7px' }} />
                            {intl.get('BloggerRanking').NonMembers} <span style={{ color: '#F7385B', cursor: 'pointer' }} onClick={() => props.navigate('/index/member')}>{intl.get('BloggerRanking').OpenMember}</span>
                            {intl.get('BloggerRanking').ViewMoreData}
                        </div> : ''
            }
        </div>



        {isPk ? <PkModel clickFun={() => setIsPk(false)} pkResultList={pkResultList} /> : ''}
        {isShare ? <ShareBloggerModel clickFun={() => getBlobPng(false)} dataList={data} /> : ''}
    </div>

}
export default withRouter(Sensation);