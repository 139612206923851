import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../utils/withRouter'
import {Image, Input, Checkbox, Select, Spin, notification, Form, Button, message, Modal} from 'antd';
import intl from 'react-intl-universal';
import { CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import * as Api from '../../service/https'
import store from '../../utils/redux/index'
import BloggerSigning from '../../components/Login/BloggerSigning'
import McnSigning from '../../components/Login/McnSigning'
import QRCode from 'qrcode.react';
const { Option } = Select;

const Login = (props)=>{
    const [textJson, setTextJson] = useState(intl.get('NewLogin'))
    const intervalRef = useRef(null);
    const timeOutRef = useRef(null);
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [isBind, setIsBind] = useState(false)
    useEffect(()=>{
        // console.log(props)
        // IsPC()
        // window.addEventListener('resize', IsPC)
        return () => {
            // window.removeEventListener('resize', IsPC)
            if(intervalRef) clearInterval(intervalRef?.current)
            if(timeOutRef) clearTimeout(timeOutRef?.current)
        }
    },[])
    const IsPC = ()=> {
        let width = document.documentElement.clientWidth
        // console.log(width, props.location.pathname.split('/')[1])
        if(width < 850 && props.location.pathname.split('/')[1] == 'login'){
            window.location.href = '/H5Login'
        }
    }

    const [isData, setIsData] = useState(true)
    const [loadingText, setLoadingText] = useState('')
    const [isForget, changeIsForget] = useState(false)
    const [isBlogger, changeBlogger] = useState(0)
    const [phone, setPhone] = useState('')
    const [isShowM, changeShowM] = useState(false)
    const [phoneType, changePhoneType] = useState('86')
    const [password, setPassword] = useState('')
    const [isCheckedR, changeR] = useState(true)
    const [isEmail, changeIsEmail] = useState(false)
    const [isEmailLogin, changeIsEmailLogin] = useState(true)
    const [isLogin, changeIsLogin] = useState(true)
    const [isVerification, setIsVerification] = useState(false)
    const [name, setName] = useState('')
    const [phoneBind, setPhoneBind] = useState('')
    const [email, setEmail] = useState('')
    const [qrCode, setQrCode] = useState('')
    const [isFly, setIsFly] = useState(false)

    useEffect(()=>{
        setPassword('')
        setCode('')
        if(isBlogger == 0)IsPC()
        if(isLogin || isBlogger==1) {
            if(props.location.state?.isBind){
                setIsBind(true)
            }else{
                setIsBind(false)
            }
            changeIsForget(false)
            if(isBlogger==1 && !isEmailLogin){
                clearInterval(timeOutRef?.current)
                getQrCode()
            }
        }
        if(!isLogin) changeIsForget(false)
        if(isBlogger != 1) clearTimeout(timeOutRef?.current)
    },[isLogin, isBlogger])
    useEffect(()=>{
        clearTimeout(timeOutRef?.current)
        if(isBlogger == 1 && !isEmailLogin){
            getQrCode()
        }
    },[isEmailLogin])

    const getQrCode = ()=>{
        clearInterval(timeOutRef?.current)
        setLoadingText('loading')
        Api.getQrCode().then(res=>{
            setLoadingText('')
            seIsNewCode(false)
            if(res.code == 200){
                setQrCode(res.data.scan_qrcode_url)
                let timeOut = setInterval(() => {
                    getCheckCode(res.data.token, res.data.client_ticket)
                }, 3000);
                timeOutRef.current = timeOut
            }
        })
    }
    const [users, setUser] = useState(null)
    useEffect(()=>{
        if(users) setIsSigning(true)
    },[users])
    const [isNewCode, seIsNewCode] = useState(false)
    const getCheckCode = (token, client_ticket)=>{
        Api.checkCode({token, client_ticket}).then(res=>{
            if(res.code == 200){
                clearTimeout(timeOutRef?.current)
                setLoadingText('')
                if(res.data.sign){
                    openNotificationWithIcon('success', '登录成功！')
                    localStorage.setItem("token", res.data.token)
                    store.dispatch({ type: 'token', value: res.data.token })
                    props.navigate('/index/bloggerRanking', {state: '/rangKing'})
                }else{
                    setUser(res.data.user)
                }
            }else if(res.code == 40008){
                clearTimeout(timeOutRef?.current)
                seIsNewCode(true)
            }else if(res.code == 40009 || res.code == 40010){
                // let timeOut = setTimeout(() => {
                //     console.log(isEmailLogin,isBlogger)
                //     if(!isEmailLogin && isBlogger)getCheckCode(token, client_ticket)
                // }, 1000);
                // timeOutRef.current = timeOut
                if(res.code == 40010){
                    setLoadingText('Scanned,loading')
                }
            }else{
                clearTimeout(timeOutRef?.current)
            }
        })
    }
    useEffect(()=>{
        setIsData(loadingText != '')
    },[loadingText])


    const changeForget = (isBoolLogin)=>{
        changeIsLogin(isBoolLogin)
        changeIsForget(false)
    }

    useEffect(()=>{
        setPassword('')
        setCode('')
    },[isVerification])

    const prefixSelector = (
        <Select style={{ width: 100 }} value={phoneType}
            getPopupContainer={() => document.getElementById('login')}
            onChange={(e)=>changePhoneType(e)}>
            <Option value="86">CN +86</Option>
        </Select>
    );

    const [codes, setCode] = useState('')
    const [isCode, getCodes] = useState(true)
    const [setIntervals, setIntervalMethod] = useState()
    let nums = 60
    const [time, setTime] = useState(nums)

    const openNotificationWithIcon = (type, message = '')=> {
        notification[type]({
          description: message, top: 100, duration: 3
        });
    };


    // 获取验证码
    let timeAll = nums
    const getCode = ()=>{
        if(isCode && phone!=''){
            getCodes(false)
            setTime(nums)
            Api.getCode({phone: phone.trim()}).then((res)=>{
                // console.log(res.data)
                if(res.code == 200){
                    openNotificationWithIcon('success', '发送成功！')
                    getTime()
                }else{
                    getCodes(true)
                }
            }).catch(()=>{
                getCodes(true)
            })
        }
    }
    // 获取验证码
    const getEmailCode = ()=>{
        let emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
        if(!emailReg.test(email.trim())){
            message.info('Email format error!')
            return
        }
        if(isCode && email!=''){
            getCodes(false)
            setTime(nums)
            Api.getEmailCode({toEmail: email.trim()}).then((res)=>{
                // console.log(res.data)
                if(res.code == 200){
                    openNotificationWithIcon('success', '发送成功！')
                    getTime()
                }else{
                    getCodes(true)
                }
            })
        }
    }
    const getTime = (valT = -1)=>{
        let times = JSON.parse(JSON.stringify(time))
        let downTime = setInterval(()=>{
            if(valT == 0 || times == 0){
                getCodes(true)
                clearInterval(intervalRef.current)
                setTime(nums)
                timeAll = nums
            }else{
                timeAll -= 1
                setTime(valT > 0 ? valT -- : times --)
            }
        },1000)
        document.addEventListener('visibilitychange', checkBrowserChange);
        intervalRef.current = downTime
    }
    const checkBrowserChange = () => {
        if (!document.hidden) {
            // console.log(timeAll)
            clearInterval(intervalRef.current)
            getCodes(timeAll == nums)
            setTime(timeAll)
            if(timeAll < nums) getTime(timeAll)
        }
    }

    const [isSuccess, setIsSuccess] = useState(false)
    // 登录/注册
    const postLogin = ()=>{
        if(isLogin){
            let obj = {
                phone: phone.trim(),
                password: password.trim(), userType: 1 //1	商家
            }
            Api.shopLogin(obj).then((res)=>{
                // console.log(res.data)
                if(res.code == 200){
                    localStorage.setItem("token", res.data.token)
                    store.dispatch({ type: 'token', value: res.data.token })
                    openNotificationWithIcon('success', '登录成功！')
                    props.navigate('/index/workbench', {state: '/workbench'})
                    // props.navigate('/perfect', {state:{uid: 'res.data.uid', phone: '19951300657'}})
                }
            })
        }else{
            let obj = {
                phone: phone.trim(), userType: 1,
                password: password.trim(), code: codes.trim()
            }
            Api.register(obj).then((res)=>{
                // console.log(res.data)
                if(res.code == 200){
                    if(res.data.status == 0){
                        props.navigate('/perfect', {state:{uid: res.data.uid, phone: phone.trim()}})
                    }else if(res.data.status == 1){
                        setIsSuccess(true)
                    }
                }
            })
        }
    }
    const getSign = ()=>{
        Api.getSign().then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                if(res.data.sign){
                    openNotificationWithIcon('success', '登录成功！')
                    props.navigate('/index/bloggerRanking', {state: '/rangKing'})
                }else{
                    onInfoChange('uid', res.data.user?.uid)
                    setIsBind(true)
                }
            }
        })
    }
    const getRedSign = ()=>{
        Api.getRedSign().then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                if(res.data.sign){
                    openNotificationWithIcon('success', '登录成功！')
                    props.navigate('/index/bloggerRanking', {state: '/rangKing'})
                }else{
                    setUser(res.data.user)
                }
            }
        })
    }
    const getMCNSing = ()=>{
        Api.getMCNSign().then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                store.dispatch({ type: 'MCN', value: res.data })
                openNotificationWithIcon('success', '登录成功！')
                props.navigate('/index/mcnMySensation', {state: '/mcnMySensation'})
            }else if(res.code == 40021){
                setIsMCNSign(true)
            }
        })
    }
    const resetPassword = ()=>{
        let obj = {
            phone: phone.trim(), userType: 1,
            password: password.trim(), code: codes.trim(),
            confirmPassword: password.trim(),
        }
        Api.forget(obj).then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                openNotificationWithIcon('success', '修改成功！')
                changeIsLogin(true)
                setPassword('')
                setTimeout(()=>{
                    window.location.reload()
                },300)
            }
        })
    }
    const resetEmailPassword = ()=>{
        let obj = {
            email: email.trim(),
            password: password, verifyCode: codes
        }
        Api.resetEmailPassword(obj).then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                openNotificationWithIcon('success', '修改成功！')
                changeIsLogin(true)
                setPassword('')
                setTimeout(()=>{
                    window.location.reload()
                },300)
            }
        })
    }

    const infoSub = ()=>{
        let obj = infos
        obj.market = obj.market.length == 0 ? '' : obj.market.toString().split(',').join(',')
        // console.log(obj)
        Api.infoSub(obj).then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                openNotificationWithIcon('success', '提交成功！')
                setIsFly(true)
            }
        })
    }

    const isSub = ()=>{
        return name==''||phoneBind.length!=11||email.indexOf('@') < 0
    }
    const isSubLR = ()=>{
        return isLogin?(phone.length!=11||password.length<6):
            ((phone.length!=11||codes.length!=6||password.length<6||!isCheckedR ||password.length>16))
    }
    const isSubY = ()=>{
        return phone.length!=11||codes.length!=6
    }
    const isSubR = ()=>{
        return phone.length!=11||password.length<6||codes.length!=6||password.length>16
    }
    const isSubER = ()=>{
        return email.indexOf('@') < 0||password.length<6||codes.length!=6||password.length>16
    }

    const isBSubY = ()=>{
        return email.indexOf('@') < 0||codes.length!=6
    }
    const isBSub = ()=>{
        return isLogin?(email.indexOf('@') < 0||password.length<6):
            (email.indexOf('@') < 0||password.length<6||codes.length!=6||!isCheckedR ||password.length>16)
    }


    const [optionList, setOptionList] = useState([])
    const [marketList, setMarketList] = useState([])
    useEffect(()=>{
        if(isBind){
            // Api.searchIndustry().then(res => {
            //     if (res.code === 200) {
            //         setOptionList(res.data)
            //     }
            // })
            Api.marketSearch().then(res => {
                if (res.code === 200) {
                    setMarketList(res.data)
                }
            })
            Api.getCargo().then(res=>{
                if(res.code == 200){
                    let all = res.data
                    setOptionList(all)
                }
            })
        }
    },[isBind])
    const [infos, setInfos] = useState({
        name:'', industryType: undefined, market: [], linkman:'', phone:'', uid: ''
    })
    const onInfoChange = (key, val)=>{
        let t = JSON.parse(JSON.stringify(infos))
        t[key] = val
        setInfos(t)
    }
    const isInfoSub = ()=>{
        return infos.name!=''&&infos.industryType!=undefined&& infos.market.length > 0&& infos.linkman!=''&&infos.phone.length==11
    }

    const user = (e)=>{
        e.stopPropagation()
        window.open('/userAgreement')
    }
    const privacy = (e)=>{
        e.stopPropagation()
        window.open('/privacyAgreement')
    }


    const [isMCNSign, setIsMCNSign] = useState(false)
    const [isSigning, setIsSigning] = useState(false)
    const closeModel = ()=>{
        setIsSigning(false)
        setIsMCNSign(false)
    }

    return <div style={{width:'100%', height:'calc(100vh - 66px)', display:'flex', justifyContent:'space-between',
        paddingTop:'66px'}}>
        <div style={{width:'100%', height:'66px', letterSpacing:'0.5px', userSelect:'none', backgroundColor: '#111', boxShadow: '0px 0px 10px 0px #F8F8F8',
            position: 'fixed', zIndex: 2, top: 0, left: 0, color:'#FFF', }}>

            <div style={{
                width: '1400px', height: '66px', display: 'flex', alignItems: 'center', margin:'0 auto',
                justifyContent:'space-between', padding:'0 10px'
            }}>
                <div style={{display:'flex', alignItems:'center'}}>
                    <img onClick={() => window.open('http://www.brandgogo.com')} style={{width:'190px',cursor:'pointer'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logo.png'} alt="" />
                    <span style={{marginLeft:'78px'}}>商家管理中心</span>
                </div>
                {/*<div onClick={()=>props.navigate('/login')} style={{width:'98px', height:'34px', */}
                {/*    border:'1px solid #F7F7F7',*/}
                {/*    borderRadius:'6px', color: '#FFFFFF', textAlign:'center', lineHeight:'32px', cursor:'pointer'}}>*/}
                {/*    卖家课堂*/}
                {/*</div>*/}

            </div>
        </div>

        <div className='loginLogo' style={{width:'calc(100% - 630px)', height:'calc(100vh - 66px)',
            // backgroundColor:'rgb(208,215,222)',
            backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/loginLogo.png)',
            backgroundSize:'cover', backgroundRepeat:'no-repeat',
            position:'fixed', top:'66px', left:0}}>
        </div>
        <div className='loginLogo' style={{width:'calc(100% - 630px)', height:'calc(100vh - 66px)'}}>
        </div>
        <div className="login" style={{width:'630px', padding:'99px'}}>
            <h2 style={{color:'#E3E3E3', fontSize:'28px', fontWeight:'bold', lineHeight:'28px', marginBottom:'12px',
                letterSpacing:'2px'}}>HELLO</h2>
            <h1 style={{color:'#333333', fontSize:'34px', fontWeight: 750, lineHeight:'34px', letterSpacing:'4px'}}>
                {isForget?intl.get('Login').ChangePassword:isLogin?'欢迎登录卖家管理中心':'欢迎注册卖家管理中心'}
            </h1>
            <div style={{width:'44px', height:'4px', backgroundColor:'#F7385B'}}></div>
            <div style={{width:'44px', height:'45px'}}></div>

            {!isForget ?
                // 商家
                <div id='login'>
                    <Form name="basic" autoComplete="off">
                        <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginBottom:'5px'}}>
                            {intl.get('Login').Phone}
                        </div>
                        <Form.Item name="phone" label="" rules={[ { required: true,
                            validator: (_, value) =>{
                                if(value.length == 11) {
                                    return Promise.resolve()
                                }else{
                                    return Promise.reject(intl.get('Login').PhoneV)
                                }
                            }
                            } ]} >
                            <div id='phones'>
                                <Input onChange={e=>setPhone(e.target.value)} addonBefore={prefixSelector} placeholder={intl.get('Login').PhoneT} />
                            </div>
                        </Form.Item>
                        {isVerification || !isLogin ? <div>
                            <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginTop:'5px', marginBottom:'5px'}}>
                                {intl.get('Login').PhoneVerificationCode}
                            </div>
                            <Form.Item label="" name="code" rules={[ { required: true,
                                validator: (_, value) =>{
                                    if(value.length ==6 ) {
                                        return Promise.resolve()
                                    }else{
                                        return Promise.reject(intl.get('Login').VerificationCodeV)
                                    }
                                }
                                } ]} >
                                <div>
                                    <Input onChange={(e)=>{setCode(e.target.value)}} value={codes} placeholder={intl.get('Login').PhoneVerificationCodeT}
                                        style={{height:'45px', paddingRight:'130px' }}/>
                                    <section onClick={getCode} style={{position:'absolute', top:'1px', right:'1px', width:'130px', height:'45px',
                                        textAlign:'center', cursor:'pointer', color: isCode?'#333333':'#F7385B',
                                        lineHeight:'45px', fontSize:'14px', letterSpacing:'0.05px',
                                        }}>{isCode?intl.get('Login').SendVerificationCode: time+'s后可重新发送'}</section>
                                </div>
                            </Form.Item>
                        </div>:''}
                        {!isVerification || !isLogin ? <div>
                            <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginTop:'5px', marginBottom:'5px'}}>{intl.get('Login').Password}</div>
                            <Form.Item label="" name="password" rules={[ { required: true,
                                validator: (_, value) =>{
                                    if(value.length >= 6 && value.length<=16) {
                                        return Promise.resolve()
                                    }else{
                                        return Promise.reject(intl.get('Login').PasswordV)
                                    }
                                }
                                } ]} >
                                <div>
                                    <Input.Password onChange={(e)=>{setPassword(e.target.value)}} value={password} placeholder={intl.get('Login').PasswordT} style={{height:'45px' }}
                                        iconRender={visible => (visible ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejian.png'} alt=""
                                        style={{width:'18px', cursor:'pointer'}}/> : <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejians.png'} alt=""
                                        style={{width:'18px', cursor:'pointer'}}/>)}/>
                                </div>
                            </Form.Item>
                        </div>:''}
                        {isLogin ? <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',
                            color:'#888888'}}>
                            {/*<span style={{cursor:'pointer', letterSpacing:'1px'}} onClick={()=>setIsVerification(!isVerification)}>*/}
                            {/*    {isVerification?intl.get('Login').PhonePasswordLogin:intl.get('Login').PhoneCodeLogin}*/}
                            {/*</span>*/}
                            <span style={{cursor:'pointer', letterSpacing:'1px'}}>&nbsp;</span>
                            <span onClick={()=>changeIsForget(true)} style={{cursor:'pointer', textDecoration:'underline'}}>{intl.get('Login').ForgetPassword}</span>
                        </div> :
                        <div style={{marginTop:'25px', fontWeight:500, letterSpacing:'0px'}}>
                            <Checkbox checked={isCheckedR} onClick={()=>changeR(!isCheckedR)}>
                                <span style={{fontSize:'12px', color:'#888888'}}>{intl.get('Login').Read}
                                <span style={{color:'#F7385B'}} onClick={(e)=>user(e)}>
                                    《{intl.get('Login').agreement}》</span>{intl.get('Login').and}<span style={{color:'#F7385B'}}
                                    onClick={(e)=>privacy(e)}>
                                        《{intl.get('Login').PrivacyPolicy}》</span>
                                </span>
                            </Checkbox>
                        </div>}
                        <div style={{width:'100%', display:'flex', justifyContent:'space-between', marginTop:'14px'}}>
                            {isVerification?<Button className='sub' onClick={()=>postLogin()} disabled={isSubY()} style={{ width:isLogin?'47%':'100%', height:'45px', borderRadius:'4px', border:0,
                                background: isSubY()?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:500, letterSpacing:'2px',
                                color:'#FFF'}}>{isLogin?intl.get('Login').ConfirmLogin: intl.get('Login').ConfirmRegistration}
                            </Button> :
                            <Button className='sub' onClick={()=>postLogin()} disabled={isSubLR()} style={{ width:isLogin?'47%':'100%', height:'45px', borderRadius:'4px', border:0,
                                background: isSubLR()?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:500, letterSpacing:'2px',
                                color:'#FFF'}}>{isLogin?intl.get('Login').ConfirmLogin: intl.get('Login').ConfirmRegistration}
                            </Button>}
                            {isLogin?<div style={{width:'47%', height:'45px', border:'1px solid #EEEEEE', lineHeight:'45px',
                            fontWeight:500, textAlign:'center', borderRadius:'4px', cursor:'pointer'}}
                            onClick={()=>changeIsLogin(!isLogin)}>还没有账号？立即注册</div>:''}
                        </div>
                        {!isLogin?<div style={{textAlign:'center', marginTop:'27px', color:'#888888', fontWeight:500}}>
                            {isLogin?intl.get('Login').NoAccount:intl.get('Login').ExistingAccount}
                            <span style={{color:'#F7385B', cursor:'pointer'}}
                            onClick={()=>changeIsLogin(!isLogin)}>{isLogin?intl.get('Login').RegisterNow:intl.get('Login').LogInNow}</span>
                        </div>:''}
                    </Form>
                </div>
            :
            // 修改密码
            <div id='login'>
                <Form name="basic" autoComplete="off">
                    <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginBottom:'5px'}}>
                        {intl.get('Login').Phone}
                    </div>
                    <Form.Item name="phone" label="" rules={[ { required: true,
                        validator: (_, value) =>{
                            if(value.length == 11) {
                                return Promise.resolve()
                            }else{
                                return Promise.reject(intl.get('Login').PhoneV)
                            }
                        }
                        } ]} >
                        <div id='phones'>
                            <Input onChange={e=>setPhone(e.target.value)} addonBefore={prefixSelector} placeholder={intl.get('Login').PhoneT} />
                        </div>
                    </Form.Item>
                    <div>
                        <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginTop:'5px', marginBottom:'5px'}}>
                            {isEmail?intl.get('Login').EmailVerificationCode:intl.get('Login').PhoneVerificationCode}
                        </div>

                        <Form.Item label="" name="code" rules={[ { required: true,
                            validator: (_, value) =>{
                                if(value.length ==6 ) {
                                    return Promise.resolve()
                                }else{
                                    return Promise.reject(intl.get('Login').VerificationCodeV)
                                }
                            }
                            } ]} >
                            <div>
                                <Input onChange={(e)=>{setCode(e.target.value)}} value={codes} placeholder={intl.get('Login').PhoneVerificationCodeT}
                                    style={{height:'45px', paddingRight:'130px' }}/>
                                <section onClick={getCode} style={{position:'absolute', top:'1px', right:'1px', width:'130px', height:'45px',
                                    textAlign:'center', cursor:'pointer', color: isCode?'#333333':'#F7385B',
                                    lineHeight:'45px', fontSize:'14px', letterSpacing:'0.05px',
                                    }}>{isCode?intl.get('Login').SendVerificationCode: time+'s后可重新发送'}</section>
                            </div>
                        </Form.Item>
                    </div>
                    <div>
                        <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginTop:'5px', marginBottom:'5px'}}>{intl.get('Login').NewPassword}</div>
                        <Form.Item label="" name="password" rules={[ { required: true,
                            validator: (_, value) =>{
                                if(value.length >= 6 && value.length<=16) {
                                    return Promise.resolve()
                                }else{
                                    return Promise.reject(intl.get('Login').PasswordV)
                                }
                            }
                            } ]} >
                            <div>
                                <Input.Password onChange={(e)=>{setPassword(e.target.value)}} value={password} placeholder={intl.get('Login').PasswordT} style={{height:'45px' }}
                                    iconRender={visible => (visible ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejian.png'} alt=""
                                    style={{width:'18px', cursor:'pointer'}}/> : <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejians.png'} alt=""
                                    style={{width:'18px', cursor:'pointer'}}/>)}/>
                            </div>
                        </Form.Item>
                    </div>
                    <Button className='sub' onClick={()=>resetPassword()} disabled={isSubR()} style={{ width:'100%', height:'45px', borderRadius:'4px', border:0, marginTop:'14px',
                        background: isSubR()?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:500, letterSpacing:'2px',
                        color:'#FFF'}}>{intl.get('Login').ConfirmModification}
                    </Button>
                    <div style={{textAlign:'center', marginTop:'27px', color:'#888888', fontWeight:500}}>
                        <span onClick={()=>changeForget(true)} style={{cursor:'pointer', color:'rgb(247, 56, 91)'}}>{intl.get('Header').Login}</span>
                         ｜ {intl.get('Login').NoAccount}
                        <span style={{color:'#F7385B', cursor:'pointer'}}
                        onClick={()=>changeForget(false)}>{intl.get('Login').RegisterNow}</span>
                    </div>
                </Form>
            </div>}

            {!isForget?<div style={{width:'100%', marginTop:'102px', height:'200px', fontWeight:500}}>
                <div style={{width:'100%', textAlign:'center'}}>— 入驻要求和所需材料 —</div>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', color:'#666666',marginTop:'29px'}}>
                    <a target="_blank" href={'https://www.feishu.cn/download?share_chat_token=fc6oc9b9-7b8a-4d86-aeea-80f5cdaccfd8'} style={{width:'134px', height:'78px', cursor: 'pointer',
                    backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/lc.png)',
                    backgroundSize:'100% 100%', color:'#666666', padding:'19px 14px'}}>{'入驻流程 >'}</a>
                    <a target="_blank" href={'https://www.feishu.cn/download?share_chat_token=fc6oc9b9-7b8a-4d86-aeea-80f5cdaccfd8'} style={{width:'134px', height:'78px', cursor: 'pointer',
                    backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/zg.png)',
                    backgroundSize:'100% 100%', color:'#666666', padding:'19px 14px'}}>{'资格要求 >'}</a>
                    <a target="_blank" href={'https://www.feishu.cn/download?share_chat_token=fc6oc9b9-7b8a-4d86-aeea-80f5cdaccfd8'} style={{width:'134px', height:'78px', cursor: 'pointer',
                    backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/wt.png)',
                    backgroundSize:'100% 100%', color:'#666666', padding:'19px 14px'}}>{'常见问题 >'}</a>
                </div>
            </div>:''}
        </div>


        {isSigning ? <BloggerSigning closeModel={closeModel} users={users} isEmailLogin={isEmailLogin} /> : ''}
        {isMCNSign ? <McnSigning closeModel={closeModel} /> : ''}

        <Modal centered visible={isSuccess} footer={null} onCancel={e => { setIsSuccess(false) }}
            // getContainer={() => document.getElementById('Status1')}
            // bodyStyle={{
            //     padding: '0', backgroundColor: '#FFFFFF',
            //     width: 462, height: 466,
            // }}
        >
            <div style={{ textAlign: 'center', paddingTop: '55px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/stepsSuccess.png'} alt=""
                    style={{ width: '104px', marginTop: '40px' }} />
                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop:'10px', marginBottom:'15px', lineHeight:'25px' }}>尊敬的{phone}用户<br/>您的信息提交成功！</h3>
                <div style={{fontWeight:600, lineHeight:'24px'}}>我们将对资料进行审核，请保持电话畅通，<br/>工作人员将<span style={{color:'#F7385B'}}>在3-5个工作日</span>内与您联系！</div>
                <div style={{
                    width: '95px', height: '30px', borderRadius: '15px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '30px', color: '#FFF', margin: '38px auto',
                    background: '#F7385B', fontWeight: 500,
                }}
                onClick={()=>props.navigate('/')} >
                    {['去首页']}
                </div>
            </div>
        </Modal>

    </div>
}
export default withRouter(Login);
