import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../utils/withRouter'
import { Image, Tag, Skeleton, Divider, List, Spin, Pagination, message, Upload, notification, Input, InputNumber, Radio } from 'antd';
import { baseUrl } from '../../service/request'
import InfiniteScroll from 'react-infinite-scroll-component';
import * as Api from '../../service/https';
import '../../asset/css/LivingDemand.scss'
import intl from 'react-intl-universal';
import Modal from 'antd/lib/modal/Modal';
import Slider from "react-slick";
import QRCode from 'qrcode.react';
import store from '../../utils/redux/index'
import toKMB from '../../utils/KMB'
import PkModel from '../PkModel';
import { useNavigate } from "react-router-dom";
import Enum from '../../utils/Enum/Enum'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div style={{ backgroundColor: '#EEEEEE', borderRadius: '100px', width: '22px', height: '22px', textAlign: 'center', lineHeight: '18px', cursor: 'pointer', position: 'absolute', top: 'calc(50% - 11px)', left: '-25px' }} onClick={onClick}><img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou3.png'} alt="" /></div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div style={{ backgroundColor: '#EEEEEE', borderRadius: '100px', width: '22px', height: '22px', textAlign: 'center', lineHeight: '18px', cursor: 'pointer', position: 'absolute', top: 'calc(50% - 11px)', right: '-25px' }} onClick={onClick}><img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou2.png'} alt="" /></div>
    );
}

const Status = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
    const [textJson, setTextJson] = useState(intl.get('LivingDemand'))
    const [textJsonD, setTextJsonD] = useState(intl.get('ReleaseDemand'))
    const [state, setSate] = useState(store.getState());
    const navigate = useNavigate();
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    const [params, setParams] = useState({
        target: props.target, classification: props.classification,
        media: props.media,
        cooperationWay: 1,
        merchantCooperationEnum: props.merchantCooperationEnum,
        bloggerCooperationEnum: props.merchantCooperationEnum,
        sendSample: props.sendSample,
        orderWay: props.orderWay,
        sortWay: props.orderWay == 2 ? props.sortWay : props.sortTimeWay,
        filterName: props.filterName,
        current: 1,
        size: 10
    })
    const [applyParams, setApplyParams] = useState({})
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [showIndex, setShowIndex] = useState(-1)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [payType, setPayType] = useState(0)
    const [successVisible, setSuccessVisible] = useState(false)
    const [payload, setPayload] = useState(false)
    const [bloggerCurrent, setBloggerCurrent] = useState(-1)
    const [bloggerTotal, setBloggerTotal] = useState(0)
    const [bloglist, setBloglist] = useState([])
    const [listId, setListId] = useState()
    const [demandId, setDemandId] = useState()
    const [applicationId, setApplicationId] = useState(-1)
    const [payUrl, setPayurl] = useState('')
    const [reload, setReload] = useState(false)
    const [cost, setCost] = useState({})
    const [userInfo, setUserInfo] = useState({})
    const [id, setID] = useState({})
    const [isApply, setApply] = useState(false)
    const [newBlogList, setNewBlogList] = useState({})
    let interval = useRef()


    const [statusList, setStatusList] = useState([
        {
            id: 0,
            text: intl.get('DemandHall')['待合作'], color: '#FFC146'
        },
        {
            id: 1,
            text: state.userInfo?.userType === 1 ? intl.get('DemandHall')['待合作'] : intl.get('DemandHall')['已申请'], color: '#FFC146'
        },
        {
            id: 2,
            text: intl.get('DemandHall')['合作中'], color: '#FFC146'
        },
        {
            id: 3,
            text: intl.get('DemandHall')['待确认'], color: '#FFC146'
        },
        {
            id: 4,
            text: intl.get('DemandHall')['合作成功'], color: '#68C33B'
        },
        {
            id: 5,
            text: intl.get('DemandHall')['失效申请'], color: '#F7385B'
        },
        {
            id: 6,
            text: intl.get('DemandHall')['商家指派'], color: '#32A7F5'
        },
    ])

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SampleNextArrow />,
        nextArrow: <SamplePrevArrow />
    };

    useEffect(() => {
        // insertHistory()
        getUserInfoFun()
        return () => {
            clearInterval(interval.current);
        }
    }, [])


    const goToRelease = (item) => {
        let obj = item
        if (item.sendWay == 1) {
            Api.redSAccount({ id: obj.userIdBlogger }).then(res => {
                if (res.code == 200) {
                    obj.blogger = res.data
                    props.navigate('/index/releaseDemand', { state: obj })
                }
            })
        } else {
            props.navigate('/index/releaseDemand', { state: obj })
        }
    }


    const getUserInfoFun = () => {
        Api.getUserInfo().then(res => {
            if (res.code === 200) {
                if (res.data.userType === 2)
                setUserInfo(res.data)
            }
        })
    }

    useEffect(() => {
        if (props.target && props.merchantCooperationEnum && props.merchantCooperationEnum) {
            let t = JSON.parse(JSON.stringify(params))
            t.target = props.target
            t.merchantCooperationEnum = props.merchantCooperationEnum
            t.bloggerCooperationEnum = props.merchantCooperationEnum
            t.classification = props.classification
            t.sendSample = props.sendSample
            t.orderWay = props.orderWay
            t.sortWay = props.orderWay == 2 ? props.sortWay : props.sortTimeWay
            t.filterName = props.filterName
            t.current = 1
            setShowIndex(-1)
            setBloglist([])
            setParams(t)
        }
    }, [props.target, props.merchantCooperationEnum, props.classification, props.sendSample, props.sortWay, props.sortTimeWay, props.orderWay, props.filterName])

    useEffect(() => {
        if (state.userInfo?.userType && params.merchantCooperationEnum && props.target)
            if (state.userInfo?.userType === 1) {
                getMerchantList()
            } else {
                getBloggerApplicationsLists()
            }
    }, [params.target, params.current, params.merchantCooperationEnum, reload, state.userInfo?.userType, params.classification, params.sendSample, params.sortWay, params.sortTimeWay, params.orderWay, params.filterName])

    const loadMoreData = e => {
        let t = JSON.parse(JSON.stringify(params))
        t.current++
        setParams(t)
    }

    const [isLoadings, setIsLoadings] = useState(false)
    const getMerchantList = (e) => {
        setIsLoadings(true)
        let data = params
        console.log(params)
        Api.getMerchantList(data).then(async res => {
            setIsLoadings(false)
            if (res.code === 40019) {
                // navigate(`/index/EnterpriseCertification`)
            }
            if (res.code === 200) {
                if (state.userInfo?.userType === 1) {
                    let t = JSON.parse(JSON.stringify(newBlogList))
                    for (var i = 0; i < res.data.demandInfos.length; i++) {
                        if (res.data.demandInfos[i].sendWay === 1) {
                            await getBloggerListsB(res.data.demandInfos[i].target, res.data.demandInfos[i].listId, res.data.demandInfos[i].demandId, res.data.demandInfos[i].media).then(res2 => {
                                t[res.data.demandInfos[i].listId] = res2
                            })
                        }
                    }
                    setNewBlogList(t)
                }
                setList(params.current === 1 ? res.data.demandInfos : [...list, ...res.data.demandInfos])
                setTotal(res.data.total)
            }
        })
    }

    const getBloggerApplicationsLists = e => {
        setIsLoadings(true)
        let data = params
        Api.getBloggerApplicationsLists(data).then(res => {
            setIsLoadings(false)
            if (res.code === 200) {
                setList(params.current === 1 ? res.data.demandInfos : [...list, ...res.data.demandInfos])
                setTotal(res.data.total)
            }
        })
    }

    // 获取PK
    const [isPk, setIsPk] = useState(false)
    const [imgList, setImgList] = useState([])
    const insertHistory = () => {
        Api.insertHistory().then(res => {
            if (res.code == 200) {
                notification.destroy()
                setImgList(res.data)
            }
        })
    }

    useEffect(() => {
        if (imgList.length > 0) {
            openNotification()
        }
    }, [imgList])

    useEffect(() => {
        if (imgList.length > 0 && !isPk) {
            openNotification()
        }
    }, [isPk])

    const openNotification = () => {
        notification.open({
            duration: null,
            getContainer: () => document.getElementById('Status'),
            placement: 'bottomLeft',
            message: <div style={{ fontWeight: 500 }}>{intl.get('BloggerComparison').BloggerComparison} <span style={{ color: '#888888' }}>{imgList.length}/6</span> </div>,
            description: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                <div style={{ display: 'flex' }}>
                    {
                        imgList?.map((item, index) => {
                            return <img key={index} src={item.avatarMedium} alt=""
                                style={{
                                    width: '40px', height: '40px', borderRadius: '20px', marginRight: '-12px',
                                    border: '3px solid #FFF', position: 'relative', zIndex: 10 - index
                                }} />
                        })
                    }
                </div>
                <div style={{
                    width: '80px', height: '30px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                    borderRadius: '4px', cursor: 'pointer'
                }}
                    onClick={() => pkResult()}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pkdb.png'} alt=""
                        style={{ width: '14px', marginRight: '4px', }} />{intl.get('BloggerComparison').Contrast}
                </div>
            </div>,
            className: 'custom-class',
            style: {
                width: 350,
            },
            closeIcon: <div>{intl.get('BloggerComparison').Empty}</div>,
            onClose: () => {
                notification.destroy()
                Api.delAllPK().then(res => {
                    if (res.code == 200) {
                        insertHistory()
                    }
                })
            }
        });
    };

    const [pkResultList, setPkResultList] = useState([])

    const pkResult = () => {
        let obj = {
            bloggerList: imgList, type: 1
        }
        Api.bloggerCompare(obj).then(res => {
            if (res.code == 200) {
                setPkResultList(res.data)
                setIsPk(true)
            }
        })
    }

    const addPK = (e, item, index) => {
        e.stopPropagation();
        if (imgList.length < 6) {
            let obj = {
                bloggerId: item.id,
                uniqueId: item.tiktok,
                nickname: item.nickname,
                avatarMedium: item.avatarMedium
            }
            Api.insertPk(obj).then(res => {
                if (res.code == 200) {
                    insertHistory()
                }
            })
        }
    }

    const [videoVisible, setVideoVisible] = useState(false)
    const [url, setUrl] = useState()

    const playVideo = e => {
        if (e) {
            setVideoVisible(true)
            setUrl(e)
        }
    }

    const [walletBalance, setWalletBalance] = useState(0)
    useEffect(() => {
        if (applicationId !== -1 && applicationId){
            Api.getWallet().then(res=>{
                if(res.code == 200){
                    setWalletBalance(res.data.walletBalance || res.data.walletBalanceCNY)
                    setIsModalVisible(true)
                }
            })
        }
    }, [applicationId, payType])

    const cooperate = e => {
        if(walletBalance >= cost.application?.cost){
            setPayload(true)
            let data = {
                target: cost.target,
                media: cost.media,
                cooperationWay: 1,
                listId: cost.listId,
                demandId: cost.demandId,
                applicationId: cost.application?.id,
                payType: 'Balance',
                asettleWay: cost.asettleWay
            }
            Api.payCooperate(data).then(res => {
                setPayload(false)
                if (res.code === 200) {
                    if (cost.asettleWay === 1) {
                        setSuccessVisible(true)
                        setIsModalVisible(false)
                        setList([])
                        setBloggerCurrent(1)
                        return
                    }
                    if (res.data.payStatus === 1) {
                        setSuccessVisible(true)
                        setIsModalVisible(false)
                        setList([])
                        setBloggerCurrent(1)
                    }
                }
            })
        }else{
            message.warning('余额不足，请充值！')
        }
    }

    useEffect(() => {
        if (showIndex !== -1)
            getBloggerLists()
    }, [bloggerCurrent, showIndex])

    const getBloggerLists = e => {
        let data = {
            target: list[showIndex].target,
            media: list[showIndex].media,
            cooperationWay: 1,
            listId: listId,
            demandId: demandId,
            size: 4,
            current: bloggerCurrent
        }
        Api.applicationsBlogger(data).then(res => {
            if (res.code === 200) {
                setBloglist(res.data.demandInfos)
                setBloggerTotal(res.data.total)
                if(res.data.total == 0) message.info('暂无合作的红人！')
            }
        })
    }

    const getBloggerListsB = (target, listId, demandId, media) => {
        return new Promise((resolve, reject) => {
            let data = {
                target: target,
                media: media,
                cooperationWay: 1,
                listId: listId,
                demandId: demandId,
                size: 4,
                current: 1
            }
            Api.applicationsBlogger(data).then(res => {
                if (res.code === 200) {
                    resolve(res.data.demandInfos)
                }
            })
        })
    }
    const [videoData, setVideoData] = useState(null)
    const bloggerConfirm = () => {
        if(videoData){
            // console.log(videoData)
            Api.bloggerConfirm(videoData).then(res => {
                setLoading(true)
                if (res.code === 200) {
                    message.success(textJson['提交成功！'])
                    setList([])
                    setReload(!reload)
                    setBloggerCurrent(1)
                    setVideoData(null)
                }
            })
        }
    }

    const uploadProps = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/video/upload',
        showUploadList: false,
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'video/mp4'
            if (!isJpgOrPng) {
                message.error(intl.get('UploadVideo')['请上传正确的视频！']);
            }
            const isLt2M = file.size / 1024 / 1024 < 200;
            if (!isLt2M) {
                message.error(intl.get('UploadVideo')['视频过大，请上传200MB以下的视频！']);
            }
            return isJpgOrPng && isLt2M;
        },
        headers: {
            'x-token': localStorage.getItem('token')
        }
    };

    const [loading, setLoading] = useState(true)
    const onChange = (info, item) => {
        if (info.file.status === 'uploading') {
            if (loading) {
                message.loading(intl.get('UploadVideo')['上传中...'], 0);
                // console.log(file, fileList);
            }
            setLoading(false)
        }
        if (info.file.status === 'done') {
            message.destroy()
            let data = {
                target: item.target,
                media: item.media,
                cooperationWay: 1,
                listId: item.listId,
                demandId: item.demandId,
                applicationId: item.application.id,
                confirmInfo: info.file.response.data
            }
            setVideoData(data)
        } else if (info.file.status === 'error') {
            setLoading(false)
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    const merchantSuccess = (item) => {
        let data = {
            target: item.target,
            media: item.media,
            cooperationWay: 1,
            listId: item.listId,
            demandId: item.demandId,
            applicationId: item.application.id,
        }
        Api.merchantSuccess(data).then(res => {
            if (res.code === 200) {
                message.success(textJson["确认成功！"])
                setList([])
                setReload(!reload)
                setBloggerCurrent(1)
            }
        })
    }

    const toDetail = item => {
        let obj = {
            target: item.target,
            media: item.media,
            cooperationWay: 1,
            listId: item.listId,
            demandId: item.demandId,
        }
        props.navigate('/index/videoDemandDetail', { state: obj })
    }

    const isChecked = (item) => {
        let bools = false
        if (item) {
            for (let index = 0; index < imgList.length; index++) {
                const e = imgList[index]
                if (item.id == e.bloggerId) {
                    bools = true
                    break
                }
            }
        }
        return bools
    }

    const delPkItem = (e, item) => {
        e.stopPropagation()
        let delIndex = -1
        for (let index = 0; index < imgList.length; index++) {
            const e = imgList[index]
            if (item.id == e.bloggerId) {
                delIndex = index
                break
            }
        }
        if (delIndex > -1) {
            Api.delItemPK({ inventoryId: imgList[delIndex].id }).then(res => {
                if (res.code == 200) {
                    insertHistory()
                }
            })
        }
    }

    const refuse = (item, index) => {
        let data = {
            target: item.target,
            media: item.media,
            cooperationWay: 1,
            listId: item.listId,
            demandId: item.demandId,
            asettleWay: item.asettleWay
        }
        Api.refuse(data).then(res => {
            if (res.code === 200) {
                message.success(textJson['拒绝成功！'])
                let t = total - 1
                setTotal(t)
                let s = JSON.parse(JSON.stringify(list))
                s.splice(index, 1)
                setList(s)
            } else {
                message.error(textJson['拒绝失败！'])
            }
        })
    }

    const listNode = (item, index) => (
        <div key={item.demandId} style={{ marginTop: '12px', backgroundColor: '#fff' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flex: 1, minWidth: 0, padding: '30px 0px 30px 30px' }}>
                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '200px', marginRight: '28px',
                        backgroundImage: 'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png)', backgroundSize: 'cover',
                        backgroundPosition: '50% 50%'
                    }}>
                        <Slider {...settings} style={{ width: '130px', height: '180px' }}>
                            {
                                item.target === 2 ?
                                    <div>
                                        <div style={{ width: '130px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', position: 'relative' }}>
                                            <Image
                                                width={130}
                                                src={item.marketList?.logo || ''}
                                                fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                                alt='' />
                                        </div>
                                    </div>
                                    : ''
                            }
                            {
                                item.marketList?.videos?.map((item, index) => (
                                    <div key={index}>
                                        <div style={{ width: '130px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', position: 'relative', cursor: 'pointer' }} onClick={e => playVideo(item)}>
                                            <Image
                                                width={130}
                                                src={item + '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'}
                                                fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                                preview={false} alt='' />
                                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/player_red.png'} style={{ position: 'absolute' }} alt="" />
                                            {/* <div style={{ position: 'absolute', bottom: '12px', color: '#fff' }}>0:59</div> */}
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                item.marketList?.imgs?.map((item, index) => (
                                    <div key={index}>
                                        <div style={{ width: '130px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', position: 'relative' }}>
                                            <Image
                                                width={130}
                                                src={item || ''}
                                                fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                                alt='' />
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                    <div onClick={e => toDetail(item)} style={{ paddingRight: '40px', flex: 1, minWidth: 0 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontSize: '16px', fontWeight: '800', marginRight: '14px' }}>
                                {item?.target === 2 ? item?.marketList?.brand : item?.marketList?.title}
                            </div>
                            <div>
                                {
                                    item?.item?.sendSample === 1 ?
                                        item?.asettleWay === 1 ?
                                            <Tag color="green">{textJson['支持样品寄送（寄样抵佣金）']}</Tag>
                                            :
                                            <Tag color="green">{textJson['支持样品寄送']}</Tag>
                                        :
                                        <Tag color="error">{textJson['不支持样品寄送']}</Tag>
                                }
                            </div>
                        </div>
                        <div style={{ marginTop: '10px', whiteSpace: 'nowrap', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', color: '#333333', fontWeight: '600' }}>
                            {
                                item?.marketList?.intro
                            }
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <span style={{ background: '#FFE7E9', color: '#F7385B', fontSize: '12px', border: '1px solid #F7385B', padding: '1px 8px', borderRadius: '3px', display: 'inline-block', marginBottom: '4px', marginRight: '8px' }}>
                                {intl.get('ReleaseDemand')[Enum.propertiesTarget[item.target].val]} | {intl.get('ReleaseDemand')['短视频']}
                            </span>
                            {
                                <Tag>{textJson["品牌名称："]}{item.marketList?.brand}</Tag>
                            }
                            <Tag>{textJson["所属行业："]}
                                {
                                    item?.marketList?.biz?.val
                                }
                            </Tag>
                            <Tag>{textJson["媒体传播："]}<span><img style={{ width: 13, height: 13 }} src={Enum.propertiesMedia[item.media].icon} alt="" /><span>{Enum.propertiesMedia[item.media].val}</span></span></Tag>
                        </div>
                        <div style={{ marginTop: '10px', color: '#888888' }}>{textJsonD["红人截稿日期"]}：<span style={{ fontFamily: 'neue' }}>{item.item?.bloggerPubDeadline?.substring(0, 10)}</span></div>
                        {item.merchantCooperationEnum > 1 ? <div style={{ marginTop: '3px', color: '#888888', display: 'flex', alignItems: 'center' }}>
                            {textJson["总计合作佣金："]}
                            {
                                item?.item?.sendSample === 1 && item?.asettleWay === 1 ?
                                    <div className='flex-ac'>
                                        <div style={{ fontSize: '14px', fontWeight: '800', color: '#F7385B' }}>
                                            $0
                                        </div>
                                        <div style={{ color: '#F7385B', fontSize: '14px', marginLeft: '10px' }}>({textJsonD["寄样抵佣金"]})</div>
                                        <div style={{ color: '#888888', marginLeft: '8px' }}>
                                            ({intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + item?.item?.duration + 's' : item?.item?.duration + 's内'})
                                        </div>
                                    </div>
                                    :
                                    <div className='flex-ac'>
                                        <div style={{ fontSize: '14px', fontWeight: '800', color: '#F7385B' }}>
                                            ${item?.application?.costDollar || (item.userInfo?.videoQuotationDollar * item?.item?.duration / 60).toFixed(2)}
                                        </div>
                                        <div style={{ color: '#F7385B', marginLeft: '8px' }}>
                                            ({textJson['红人视频单价']}{store.getState().language === 'en' ? ' in $' + (item.userInfo?.videoQuotationDollar || 0) + '/60s' : '$' + (item.userInfo?.videoQuotationDollar || 0) + '/' + intl.get('NewLogin')['60s内']} * {intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + item?.item?.duration + 's' : item?.item?.duration + 's内'})
                                        </div>
                                    </div>
                            }
                        </div>:<div style={{ marginTop: '3px', color: '#888888' }}>{intl.get('VideoDetail')['视频时长']}：<span style={{ fontFamily: 'neue' }}>{item.item?.duration}s</span></div>}
                    </div>
                </div >
                {
                    (item.merchantCooperationEnum === 1 || item.merchantCooperationEnum === 0) ?
                        state.userInfo?.userType === 1 ?
                            <div style={{ minWidth: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '30px 0px 30px 0', borderLeft: '1px solid #eee', position: 'relative' }}>
                                <div style={{
                                    minWidth: '74px', textAlign: 'center', position: 'absolute', top: '-30px', right: 0, background: statusList[item.merchantCooperationEnum].color,
                                    fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                }}>
                                    {statusList[item.merchantCooperationEnum].text}
                                </div>
                                <div style={{ color: '#666666' }}>{textJson["博主截稿日期"]}</div>
                                <div style={{ color: '#F7385B' }}>{item.item?.bloggerPubDeadline?.substring(0, 10)}</div>
                                <div style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }} onClick={e => toDetail(item)}>{textJson["查看需求详情"]}</div>
                                {item.sendWay === 1 ? '' :
                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '18px', border: '1px solid #eee' }}
                                        onClick={e => {
                                            setBloglist([])
                                            if (index === showIndex) {
                                                setShowIndex(-1);
                                                setBloggerCurrent(-1)
                                            } else {
                                                setListId(item.listId)
                                                setDemandId(item.demandId)
                                                setShowIndex(index)
                                                setBloggerCurrent(1)
                                            }
                                        }}
                                    >{textJson["申请合作的博主"]}</div>}
                            </div>
                            :
                            item.application?.status == 6 ? <div style={{ minWidth: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '30px 0px 30px 0', borderLeft: '1px solid #eee', position: 'relative' }}>
                                <div
                                    style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }}
                                    onClick={() => {
                                        setApply(true)
                                        let data = {
                                            index: index,
                                            listId: item.listId,
                                            demandId: item.demandId,
                                            sendSample: item.item.sendSample,
                                            // costFrom: item.costFrom,
                                            // costTo: item.costTo,
                                            duration: item.item?.duration,
                                            asettleWay: item?.asettleWay,
                                            target: item?.target,
                                            media: item?.media,
                                        }
                                        setID(data)
                                        if (data.sendSample === 1 && data.asettleWay === 1) {
                                            applyChange(0, 'cost')
                                        } else {
                                            applyChange(Math.floor(data.duration / 60) * (userInfo.redAccountDetail?.videoQuotationDollar || 0), 'costDollar')
                                        }
                                    }}>{textJson["同意与TA合作"]}</div>
                                <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '18px', border: '1px solid #eee' }} onClick={e => refuse(item, index)}>{textJson["拒绝与TA合作"]}</div>
                                <div style={{ width: '160px', height: '38px', lineHeight: '36px', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }} onClick={e => toDetail(item)}>{textJson["查看需求详情"]}</div>
                                <div style={{
                                    minWidth: '74px', textAlign: 'center', position: 'absolute', top: '-30px', right: 0, background: statusList[item.application?.status].color,
                                    fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                }}>
                                    {statusList[item.application.status].text}
                                </div>
                            </div> :
                                <div style={{ minWidth: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '30px 0px 30px 0', borderLeft: '1px solid #eee', position: 'relative' }}>
                                    <div style={{ color: '#666666' }}>{textJson["总计合作佣金"]}&nbsp;<span style={{ color: '#F7385B', fontFamily: 'neue', fontWeight: '800' }}>${item.application?.costDollar
                                        || Math.floor(item.item?.duration / 60) * (item?.userInfo?.videoQuotationDollar || 0)
                                        || 0}</span></div>
                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '18px', border: '1px solid #eee' }} onClick={e => toDetail(item)}>{textJson["查看需求详情"]}</div>
                                    <div style={{ color: '#F7385B', marginTop: '15px' }}>{textJson['等待商家处理']}</div>
                                    <div style={{
                                        minWidth: '74px', textAlign: 'center', position: 'absolute', top: '-30px', right: 0, background: statusList[item.merchantCooperationEnum].color,
                                        fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                    }}>
                                        {statusList[item.merchantCooperationEnum].text}
                                    </div>
                                </div>
                        :
                        item.merchantCooperationEnum === 2 ?
                            state.userInfo?.userType === 1 ?
                                <div style={{ minWidth: '320px', display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: 'linear-gradient(90deg, #FFEEF1, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF)', padding: '30px 0', position: 'relative' }}>
                                    <div style={{
                                        minWidth: '74px', textAlign: 'center', position: 'absolute', top: 0, right: 0, background: statusList[item.merchantCooperationEnum].color,
                                        fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                    }}>
                                        {statusList[item.merchantCooperationEnum].text}
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                            <Image src={item.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{item.userInfo?.nickname}</div>
                                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} style={{ width: 14, height: 14, marginLeft: '6px' }} alt="" />
                                            </div>
                                            <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {item.userInfo?.tiktok}</div>
                                        </div>
                                    </div>
                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }} onClick={e => window.open('https://www.tiktok.com/@' + item.userInfo?.tiktok)}>{textJson["查看博主主页"]}</div>
                                    <div style={{ fontSize: '12px', color: '#666666', marginTop: '20px', fontWeight: '500', textAlign: 'center', width: '240px' }}>{textJson["博主创作中，将于"]}<span style={{ color: '#F7385B', fontFamily: 'neue', fontSize: 14, fontWeight: 600 }}>{item.item.bloggerPubDeadline?.substring(0, 10)}</span>{textJson["前上传短视频作品，请耐心等待"]}</div>
                                </div>
                                :
                                <div style={{ minWidth: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '30px 0px 30px 0', borderLeft: '1px solid #eee', position: 'relative' }}>
                                    <div style={{ color: '#666666', width: '160px', textAlign: 'center' }}>{textJson["请于"]}<span style={{ color: '#F7385B' }}>{item.item?.bloggerPubDeadline?.substring(0, 10)}</span>{textJson["前上传短视频"]}</div>
                                    <Upload {...uploadProps} onChange={e => onChange(e, item)}>
                                        <div style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }}>{textJson["上传短视频"]}</div>
                                    </Upload>
                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '18px', border: '1px solid #eee' }} onClick={e => toDetail(item)}>{textJson["查看需求详情"]}</div>
                                    <div style={{
                                        minWidth: '74px', textAlign: 'center', position: 'absolute', top: '-30px', right: 0, background: statusList[item.merchantCooperationEnum].color,
                                        fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                    }}>
                                        {statusList[item.merchantCooperationEnum].text}
                                    </div>
                                </div>
                            :
                            item.merchantCooperationEnum === 3 ?
                                state.userInfo?.userType === 1 ?
                                    <div style={{ minWidth: '320px', display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: 'linear-gradient(90deg, #FFEEF1, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF)', padding: '30px 0', position: 'relative' }}>
                                        <div style={{
                                            minWidth: '74px', textAlign: 'center', position: 'absolute', top: 0, right: 0, background: statusList[item.merchantCooperationEnum].color,
                                            fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                        }}>
                                            {statusList[item.merchantCooperationEnum].text}
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                                <Image src={item.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{item.userInfo?.nickname}</div>
                                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} style={{ width: 14, height: 14, marginLeft: '6px' }} alt="" />
                                                </div>
                                                <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {item.userInfo?.tiktok}</div>
                                            </div>
                                        </div>
                                        <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }} onClick={e => window.open('https://www.tiktok.com/@' + item.userInfo?.tiktok)}>{textJson["查看博主主页"]}</div>
                                        <div style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '10px' }} onClick={e => merchantSuccess(item)}>{textJson["确认合作完成"]}</div>
                                        <div style={{ marginTop: '10px', fontSize: '12px', cursor: 'pointer', color: '#F7385B' }} onClick={e => playVideo(item.confirmInfo)}>{textJson["查看合作的短视频"]}</div>
                                    </div>
                                    :
                                    <div style={{ minWidth: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '30px 0px 30px 0', borderLeft: '1px solid #eee', position: 'relative' }}>
                                        <div style={{ color: '#666666', width: '160px', textAlign: 'center' }}>{textJson["等待商家确认，否则佣金将在"]}<span style={{ color: '#F7385B' }}>{textJson["7个工作日"]}</span>{textJson["内到账"]}</div>
                                        <div style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }} onClick={e => playVideo(item.confirmInfo)}>{textJson["查看短视频"]}</div>
                                        <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '18px', border: '1px solid #eee' }} onClick={e => toDetail(item)}>{textJson["查看需求详情"]}</div>
                                        <div style={{
                                            minWidth: '74px', textAlign: 'center', position: 'absolute', top: '-30px', right: 0, background: statusList[item.merchantCooperationEnum].color,
                                            fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                        }}>
                                            {statusList[item.merchantCooperationEnum].text}
                                        </div>
                                    </div>
                                :
                                item.merchantCooperationEnum === 4 ?
                                    state.userInfo?.userType === 1 ?
                                        <div style={{ minWidth: '320px', display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: 'linear-gradient(90deg, #FFEEF1, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF)', padding: '30px 0', position: 'relative' }}>
                                            <div style={{
                                                minWidth: '74px', textAlign: 'center', position: 'absolute', top: 0, right: 0, background: statusList[item.merchantCooperationEnum].color,
                                                fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                            }}>
                                                {statusList[item.merchantCooperationEnum].text}
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                                    <Image src={item.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{item.userInfo?.nickname}</div>
                                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} style={{ width: 14, height: 14, marginLeft: '6px' }} alt="" />
                                                    </div>
                                                    <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {item.userInfo?.tiktok}</div>
                                                </div>
                                            </div>
                                            <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }} onClick={e => window.open('https://www.tiktok.com/@' + item.userInfo?.tiktok)}>{textJson["查看博主主页"]}</div>
                                            <div style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }} onClick={e => playVideo(item.confirmInfo)}>{textJson["查看合作的短视频"]}</div>
                                        </div>
                                        :
                                        <div style={{ minWidth: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '30px 0px 30px 0', borderLeft: '1px solid #eee', position: 'relative' }}>
                                            <div style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }} onClick={e => playVideo(item.confirmInfo)}>{textJson["查看短视频"]}</div>
                                            <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '18px', border: '1px solid #eee' }} onClick={e => toDetail(item)}>{textJson["查看需求详情"]}</div>
                                            <div style={{
                                                minWidth: '74px', textAlign: 'center', position: 'absolute', top: '-30px', right: 0, background: statusList[item.merchantCooperationEnum].color,
                                                fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                            }}>
                                                {statusList[item.merchantCooperationEnum].text}
                                            </div>
                                        </div>
                                    :
                                    item.merchantCooperationEnum === 5 ?
                                        state.userInfo?.userType === 1 ?
                                            <div style={{ minWidth: '320px', display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: 'linear-gradient(90deg, #FFEEF1, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF)', padding: '30px 0', position: 'relative' }}>
                                                <div style={{
                                                    minWidth: '74px', textAlign: 'center', position: 'absolute', top: 0, right: 0, background: statusList[item.merchantCooperationEnum].color,
                                                    fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                                }}>
                                                    {statusList[item.merchantCooperationEnum].text}
                                                </div>
                                                <img src={"https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hzsx.png"} alt="" />
                                                <div style={{ width: '221px', fontSize: '13px', textAlign: 'center', marginTop: '18px' }}>{textJson["非常抱歉，您的需求在有效期内未收到博主的合作请求，请重新编辑后再发布"]}</div>
                                                <div style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }}
                                                    onClick={() => goToRelease(item)}>{textJson["重新编辑需求"]}</div>
                                            </div>
                                            :
                                            <div style={{ minWidth: '320px', display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: 'linear-gradient(90deg, #FFEEF1, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF)', padding: '30px 0', position: 'relative' }}>
                                                <img src={"https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hzsx.png"} alt="" />
                                                {
                                                    item.sendWay === 1 ?
                                                        <div style={{ width: '221px', fontSize: '13px', textAlign: 'center', marginTop: '18px' }}>{textJson["非常抱歉，您已经拒绝商家的合作请求，期待你们下次合作"]}</div>
                                                        :
                                                        <div style={{ width: '221px', fontSize: '13px', textAlign: 'center', marginTop: '18px' }}>{textJson["非常抱歉，您的合作申请商家未通过，感谢你的参与"]}</div>
                                                }
                                                <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '18px', border: '1px solid #eee' }} onClick={e => toDetail(item)}>{textJson["查看需求详情"]}</div>
                                                <div style={{
                                                    minWidth: '74px', textAlign: 'center', position: 'absolute', top: 0, right: 0, background: statusList[item.merchantCooperationEnum].color,
                                                    fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                                }}>
                                                    {statusList[item.merchantCooperationEnum].text}
                                                </div>
                                            </div>
                                        :
                                        item.merchantCooperationEnum === 6 ?
                                            state.userInfo?.userType === 1 ?
                                                <div style={{ minWidth: '320px', display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: 'linear-gradient(90deg, #FFEEF1, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF)', padding: '30px 0', position: 'relative' }}>
                                                    <div style={{
                                                        minWidth: '74px', textAlign: 'center', position: 'absolute', top: 0, right: 0, background: statusList[item.merchantCooperationEnum].color,
                                                        fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                                    }}>
                                                        {statusList[item.merchantCooperationEnum].text}
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                                            <Image src={item.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{item.userInfo?.nickname}</div>
                                                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} style={{ width: 14, height: 14, marginLeft: '6px' }} alt="" />
                                                            </div>
                                                            <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {item.userInfo?.tiktok}</div>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }} onClick={e => window.open('https://www.tiktok.com/@' + item.userInfo?.uniqueId)}>{textJson["查看博主主页"]}</div>
                                                    <div style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '10px' }} onClick={e => merchantSuccess(item)}>{textJson["确认合作完成"]}</div>
                                                    <div style={{ marginTop: '10px', fontSize: '12px', cursor: 'pointer', color: '#F7385B' }} onClick={e => playVideo(item.confirmInfo)}>{textJson["查看合作的短视频"]}</div>
                                                </div>
                                                :
                                                <div style={{ minWidth: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '30px 0px 30px 0', borderLeft: '1px solid #eee', position: 'relative' }}>
                                                    <div
                                                        style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }}
                                                        onClick={() => {
                                                            setApply(true)
                                                            let data = {
                                                                index: index,
                                                                listId: item.listId,
                                                                demandId: item.demandId,
                                                                sendSample: item.item.sendSample,
                                                                // costFrom: item.costFrom,
                                                                // costTo: item.costTo,
                                                                duration: item.item?.duration,
                                                                sendSample: item?.item?.sendSample,
                                                                asettleWay: item?.asettleWay,
                                                                target: item?.target,
                                                                media: item?.media,
                                                            }
                                                            setID(data)
                                                            if (data.sendSample === 1 && data.asettleWay === 1) {
                                                                applyChange(0, 'cost')
                                                            } else {
                                                                applyChange(Math.floor(data.duration / 60) * (userInfo.redAccountDetail?.videoQuotationDollar || 0), 'costDollar')
                                                            }
                                                        }}>{textJson["同意与TA合作"]}</div>
                                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '18px', border: '1px solid #eee' }} onClick={e => refuse(item, index)}>{textJson["拒绝与TA合作"]}</div>
                                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }} onClick={e => toDetail(item)}>{textJson["查看需求详情"]}</div>
                                                    <div style={{
                                                        minWidth: '74px', textAlign: 'center', position: 'absolute', top: '-30px', right: 0, background: statusList[item.merchantCooperationEnum].color,
                                                        fontSize: '12px', color: '#FFF', padding: '3px 12px', borderBottomLeftRadius: '4px'
                                                    }}>
                                                        {statusList[item.merchantCooperationEnum].text}
                                                    </div>
                                                </div>
                                            :
                                            ''
                }
            </div>
            {
                ((item.merchantCooperationEnum === 1 && showIndex === index && bloglist.length > 0) || (item.sendWay === 1 && (item.merchantCooperationEnum === 1 || item.merchantCooperationEnum === 0))) && state.userInfo?.userType === 1 ?
                    <div style={{ borderTop: '1px dashed #eee', padding: '30px', paddingLeft: '222px' }}>
                        <div style={{ marginBottom: '20px', color: '#888888', display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                {
                                    item.sendWay === 1 ?
                                        textJson["您已指定想与该红人合作，请耐心等待红人的反馈结果，一旦红人同意合作将无法修改"]
                                        :
                                        textJson["请在以下申请合作的博主中选择一位进行合作，一旦合作将无法修改"]
                                }
                            </div>
                            {
                                item.sendWay === 1 ? '' :
                                    <Pagination simple current={bloggerCurrent} total={bloggerTotal} onChange={e => setBloggerCurrent(e)} pageSize={4} />
                            }
                        </div>
                        <div style={{ display: 'flex', overflow: 'auto' }}>
                            {
                                item.sendWay === 1 ?
                                    newBlogList[item.listId]?.map((item2, index2) =>
                                        <div key={index2} style={{ width: '318px', marginRight: '14px', flexShrink: 0, position: 'relative' }}>
                                            {/* <img onClick={(e) => isChecked(item2.userInfo) ? delPkItem(e, item2.userInfo, index) : addPK(e, item2.userInfo, index)}
                                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pk' + (isChecked(item2.userInfo) ? 's' : '') + '.png'} style={{ position: 'absolute', top: 14, right: 24, cursor: 'pointer' }} alt="" /> */}
                                            <div className='blogerBox'>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                                    <Image src={item2.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                                                </div>
                                                <div style={{ marginLeft: '15px' }}>
                                                    <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{item2.userInfo?.nickname}</div>
                                                    <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {item2.userInfo?.tiktok}</div>
                                                    <div style={{ color: '#F7385B', fontSize: '12px', lineHeight: '14px' }}>{textJson["合作佣金"]}
                                                        {
                                                            item?.item?.sendSample === 1 && item?.asettleWay === 1 ?
                                                                <span style={{ fontSize: 14, fontWeight: '800', fontFamily: 'neue' }}>
                                                                    $0
                                                                </span>
                                                                :
                                                                <span style={{ fontSize: 14, fontWeight: '800', fontFamily: 'neue' }}>
                                                                    ${item2.application.costDollar
                                                                    || (Math.floor(item.item?.duration / 60) * (item?.userInfo?.videoQuotationDollar || 0))
                                                                    || 0}
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ border: '1px solid #eee', padding: '23px' }}>
                                                <div className='blogerBoxBottom'>
                                                    <div>
                                                        <div>{toKMB(item2.userInfo?.followerCount)}</div>
                                                        <div>{intl.get('BloggerRanking')["FansNum"]}</div>
                                                    </div>
                                                    <div>
                                                        <div>${item2.userInfo?.videoQuotationDollar}/60s</div>
                                                        <div>{intl.get('BloggerRanking')["视频单价"]}</div>
                                                    </div>
                                                    <div>
                                                        <div>${item2.userInfo?.liveBroadcastQuotationDollar}/1h</div>
                                                        <div>{intl.get('BloggerRanking')["直播单价"]}</div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 10 }}>
                                                    <div style={{ width: '128px', height: '32px', borderRadius: '5px', cursor: 'pointer', border: '1px solid #eee', color: '#888888', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/zhuye.png'} style={{ height: '12px', width: '12px' }} alt="" />
                                                        <div style={{ fontSize: '12px', marginLeft: '5px' }} onClick={e => window.open('https://www.tiktok.com/@' + item2.userInfo?.tiktok)}>{intl.get('BloggerRanking')["红人主页"]}</div>
                                                    </div>
                                                    <div style={{ width: '128px', height: '32px', border: '1px solid #eee', borderRadius: '5px', color: item2.application?.status == 6 || item2.application?.status == 0 ? '#888' : '#fff', background: item2.application?.status == 6 || item2.application?.status == 0 ? '#FFF' : 'linear-gradient(180deg, #F7385B, #FF716E)', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={e => {
                                                        if (item2.application?.status == 6 || item2.application?.status == 0) {
                                                            return
                                                        }
                                                        setApplicationId(item2.application.id);
                                                        setCost(item2);
                                                    }}>
                                                        {item2.application?.status == 6 || item2.application?.status == 0 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hz.png'} style={{ width: '12px' }} alt="" /> :
                                                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/cooperation.png'} style={{ height: '12px', width: '12px' }} alt="" />}
                                                        <div style={{ fontSize: '12px', marginLeft: '5px' }}>
                                                            {item2.application?.status == 6 || item2.application?.status == 0 ? '待红人接受' : item2.application?.status == 1 ? '已接受，待付款' : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    bloglist?.map((item2, index2) =>
                                        <div key={index2} style={{ width: '318px', marginRight: '14px', flexShrink: 0, position: 'relative' }}>
                                            {/* <img onClick={(e) => isChecked(item2.userInfo) ? delPkItem(e, item2.userInfo, index) : addPK(e, item2.userInfo, index)}
                                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pk' + (isChecked(item2.userInfo) ? 's' : '') + '.png'} style={{ position: 'absolute', top: 14, right: 24, cursor: 'pointer' }} alt="" /> */}
                                            <div className='blogerBox'>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                                    <Image src={item2.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                                                </div>
                                                <div style={{ marginLeft: '15px' }}>
                                                    <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{item2.userInfo?.nickname}</div>
                                                    <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {item2.userInfo?.tiktok}</div>
                                                    <div style={{ color: '#F7385B', fontSize: '12px', lineHeight: '14px' }}>{textJson["合作佣金"]}
                                                        {
                                                            item?.item?.sendSample === 1 && item?.asettleWay === 1 ?
                                                                <span style={{ fontSize: 14, fontWeight: '800', fontFamily: 'neue' }}>
                                                                    $0
                                                                </span>
                                                                :
                                                                <span style={{ fontSize: 14, fontWeight: '800', fontFamily: 'neue' }}>
                                                                    ${item2.application.costDollar
                                                                    || (Math.floor(item.item?.duration / 60) * (item?.userInfo?.videoQuotationDollar || 0))
                                                                    || 0}
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ border: '1px solid #eee', padding: '23px' }}>
                                                <div className='blogerBoxBottom'>
                                                    <div>
                                                        <div>{toKMB(item2.userInfo?.followerCount)}</div>
                                                        <div>{intl.get('BloggerRanking')["FansNum"]}</div>
                                                    </div>
                                                    <div>
                                                        <div>${item2.userInfo?.videoQuotationDollar}/60s</div>
                                                        <div>{intl.get('BloggerRanking')["视频单价"]}</div>
                                                    </div>
                                                    <div>
                                                        <div>${item2.userInfo?.liveBroadcastQuotationDollar}/1h</div>
                                                        <div>{intl.get('BloggerRanking')["直播单价"]}</div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 10 }}>
                                                    <div style={{ width: '128px', height: '32px', borderRadius: '5px', cursor: 'pointer', border: '1px solid #eee', color: '#888888', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/zhuye.png'} style={{ height: '12px', width: '12px' }} alt="" />
                                                        <div style={{ fontSize: '12px', marginLeft: '5px' }} onClick={e => window.open('https://www.tiktok.com/@' + item2.userInfo?.tiktok)}>{intl.get('BloggerRanking')["红人主页"]}</div>
                                                    </div>
                                                    {
                                                        item.merchantCooperationEnum === 1 ?
                                                            <div style={{ width: '128px', height: '32px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={e => {
                                                                setApplicationId(item2.application.id);
                                                                setCost(item2);
                                                            }}>
                                                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/cooperation.png'} style={{ height: '12px', width: '12px' }} alt="" />
                                                                <div style={{ fontSize: '12px', marginLeft: '5px' }}>{textJson["与TA合作"]}</div>
                                                            </div> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                    :
                    ''
            }
        </div >
    )

    const [isAddress, setIsAddress] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [addressIndex, setAddressIndex] = useState(-1)
    const getCurrentUserAddressList = ()=>{
        Api.getCurrentUserAddressList().then(res=>{
            if(res.code == 200){
                setAddressList(res.data)
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    if(element.defaultAddress){
                        setAddressIndex(index)
                        break
                    }
                }
            }
        })
    }
    useEffect(()=>{
        if(isApply){
            getCurrentUserAddressList()
        }
    },[isApply])
    const [addParams, setAddParams] = useState({})
    const addChange = (e, id) => {
        let t = JSON.parse(JSON.stringify(addParams))
        t[id] = e
        setAddParams(t)
    }
    const applyChange = (e, id) => {
        let t = JSON.parse(JSON.stringify(applyParams))
        t[id] = e
        setApplyParams(t)
    }
    useEffect(()=>{
        if(addressIndex > -1){
            let obj = {
                cost:0,
                costDollar: applyParams.costDollar,
                consigneeSpecificAddress: addressList[addressIndex].address ,
                consigneePostCode: addressList[addressIndex].postCode, 
                consigneePhone: addressList[addressIndex].phone, 
                consigneeName: addressList[addressIndex].contactName, 
            }
            setApplyParams(obj)
        }
    },[addressIndex])
    const addAddress = ()=>{
        if (JSON.stringify(addParams).indexOf(('""')) === -1 && JSON.stringify(addParams) !== '{}') {
            let obj = {
                uid: state.userInfo?.uid, address: addParams.consigneeSpecificAddress, 
                postCode: addParams.consigneePostCode, phone: addParams.consigneePhone, 
                contactName: addParams.consigneeName, defaultAddress: true 
            }
            // console.log(obj)
            Api.addAddress(obj).then(res=>{
                if(res.code == 200){
                    getCurrentUserAddressList()
                    setIsAddress(false)
                    setAddParams({})
                }
            })
        }else{
            message.warn("内容未填写完整！")
        }
    }


    const submit = () => {
        if ((JSON.stringify(applyParams).indexOf(('""')) === -1 && JSON.stringify(applyParams) !== '{}' && addressIndex > -1) || id.sendSample != 1) {
            let b = {
                target: id?.target,
                media: id?.media,
                cooperationWay: 1
            }
            let data = JSON.parse((JSON.stringify(id) + JSON.stringify(b)).replace(/}{/, ','));
            data = JSON.parse((JSON.stringify(data) + JSON.stringify(applyParams)).replace(/}{/, ','));
            // console.log(data)
            Api.marketApply(data).then(res => {
                if (res.code === 200) {
                    let s = JSON.parse(JSON.stringify(list));
                    s.splice(id.index, 1);
                    setList(s);
                    setApply(false);
                    setAddressIndex(-1)
                    message.success(intl.get('AccountRecharge')["申请成功！"])
                }
            })
        }
        else
            message.warn(textJson["内容未填写完整！"])
    }

    return (
        <div id='Status'>
            <Spin tip="数据加载中..." spinning={isLoadings}>
                <InfiniteScroll
                    dataLength={list.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={total > list.length}
                    // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={total === list.length && list.length > 0 ? <Divider plain>{intl.get('TopicList').Nothing} 🤐</Divider> : ''}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={list}
                        grid={{ gutter: 0, column: 1 }}
                        locale={{
                            emptyText: <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '135px' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 166, height: 159 }} alt="" />
                                <div style={{ width: '242px', textAlign: 'center', marginTop: '13px' }}>
                                    {
                                        props.merchantCooperationEnum === 1 ?
                                            textJson["抱歉，当前没有带合作的需求，你可以前往需求大厅申请合作"] :
                                            props.merchantCooperationEnum === 2 ?
                                                textJson["抱歉，当前没有合作中的需求，你可以前往需求大厅申请合作"] :
                                                props.merchantCooperationEnum === 3 ?
                                                    textJson["抱歉，当前没有待确认的需求，你可以前往需求大厅申请合作"] :
                                                    props.merchantCooperationEnum === 4 ?
                                                        textJson["抱歉，当前没有合作成功的需求，你可以前往需求大厅申请合作"] :
                                                        props.merchantCooperationEnum === 5 ?
                                                            textJson["抱歉，当前没有失效的需求，你可以前往需求大厅申请合作"] :
                                                            textJson["抱歉，当前没有合作的需求，你可以前往需求大厅申请合作"]
                                    }
                                </div>
                                {
                                    state.userInfo?.userType === 1 ?
                                        <div style={{ width: '210px', height: '40px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '40px' }} onClick={e => props.navigate('/index/releaseDemand')}>{textJson["发布需求"]}</div>
                                        :
                                        <div style={{ width: '210px', height: '40px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '40px' }} onClick={e => store.dispatch({ type: 'changeRouter', value: '/demandHall&&/demandHall' })}>{textJson["前往需求大厅"]}</div>
                                }
                            </div>
                        }}
                        renderItem={(item, index) => listNode(item, index)}
                    />
                </InfiniteScroll>
            </Spin>
            <Modal visible={isModalVisible} onCancel={e => { clearInterval(interval.current); setApplicationId(-1); setIsModalVisible(false); }} centered={true} footer={null} destroyOnClose={true}
                getContainer={() => document.getElementById('Status')}>
                <Spin spinning={payload}>
                    <div style={{ padding: '36px', width: '602px' }}>
                        <div style={{ fontWeight: '800', fontSize: '18px', marginBottom: '43px', textAlign: 'center' }}>{textJson["支付博主佣金与TA合作"]}</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '90px', color: '#666666' }}>{textJson["合作主播"]}</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                    <Image src={cost.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{cost.userInfo?.nickname}</div>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} style={{ width: 14, height: 14, marginLeft: '6px' }} alt="" />
                                    </div>
                                    <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {cost.userInfo?.tiktok}</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '43px' }}>
                            <div style={{ width: '90px', color: '#666666' }}>{textJson["内容形式"]}</div>
                            <div style={{ fontWeight: '800' }}>
                                {textJson["短视频"]} — {cost?.target === 1 ? textJson["电商卖货"] : cost?.target === 2 ? textJson["品牌传播"] : textJson["商品种草"]}
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems:'center', marginTop: '30px' }}>
                            <div style={{ width: '90px', flexShrink: '0', color: '#666666' }}>{textJson["内容形式"]}</div>
                            <div>
                                账户余额（￥{walletBalance}）
                            </div>
                            <div style={{
                                width: '86px', height: '30px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                                borderRadius: '4px', cursor: 'pointer', marginLeft:'10px'
                            }} onClick={()=> props.navigate('/index/accountRecharge')}>
                                立即充值
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '20px', borderTop: '1px dashed #eee', paddingTop: '20px' }}>
                            <div style={{ color: '#666' }}>
                                {textJson["总计费用："]}<span style={{ color: '#F7385B', fontSize: '20px', fontFamily: 'neue', fontWeight: '800' }}>${cost.application?.costDollar}</span>({textJson["博主佣金"]}: <span style={{ fontFamily: 'neue' }}>${cost.application?.costDollar - cost.application?.payTipDollar}</span> + {textJson["平台手续费"]}: <span style={{ fontFamily: 'neue' }}>${cost.application?.payTipDollar}</span>)
                            </div>
                            <div style={{
                                width: '210px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '14px',
                                borderRadius: '4px', cursor: 'pointer', marginTop: '40px'
                            }} onClick={cooperate}>
                                确定支付建立合作
                            </div>
                            <div style={{ textAlign: 'center', width: '440px', fontSize: '12px', color: '#888', marginTop: '12px' }}>{textJson["温馨提示：支付的佣金会暂存在本平台上，当博主完成合作后才会转入博主账户； 如需开票请支付成功后联系客服，如遇到付款问题请联系客"]}</div>
                        </div>
                    </div>
                </Spin>
            </Modal>
            <Modal visible={successVisible} onCancel={e => { setSuccessVisible(false) }} centered={true} footer={null} destroyOnClose={true}
                getContainer={() => document.getElementById('Status')}>
                <div style={{ padding: '16px', width: '443px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} style={{ width: 52, height: 49 }} alt="" />
                        <div style={{ fontSize: '18px', fontWeight: '800' }}>{textJson["恭喜您与该博主达成合作！"]}</div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '36px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                            <Image src={cost.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{cost.userInfo?.nickname}</div>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} style={{ width: 14, height: 14, marginLeft: '6px' }} alt="" />
                            </div>
                            <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {cost.userInfo?.tiktok}</div>
                        </div>
                    </div>
                    <div style={{ width: 294, textAlign: 'center', marginTop: '24px', fontWeight: '500' }}>{textJson["请耐心等待，博主将于2022-04-01 18:30:00前上传短视频作品，届时请您注意查看"]?.replace(/2022-04-01 18:30:00/, cost.item?.bloggerPubDeadline?.substring(0, 10))}</div>
                    <div style={{
                        width: '210px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                        display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                        borderRadius: '4px', cursor: 'pointer', marginTop: '50px'
                    }} onClick={e => { setSuccessVisible(false) }}>
                        {textJson["我知道啦"]}
                    </div>
                </div>
            </Modal>
            <Modal visible={videoVisible} centered={true} footer={null} onCancel={e => { setVideoVisible(false) }} destroyOnClose={true}
                getContainer={() => document.getElementById('Status')}>
                <div style={{ padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <video width="800" height="500" controls>
                        <source src={url} />
                        您的浏览器不支持 video 标签。
                    </video>
                </div>
            </Modal>
            <Modal visible={videoData} centered={true} cancelText={intl.get('UploadVideo')['取消上传']} okText={intl.get('UploadVideo')['确定上传']}
            onOk={()=>bloggerConfirm()} onCancel={e => setVideoData(null) } destroyOnClose={true}
                getContainer={() => document.getElementById('Status')}>
                <div style={{ padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <video width="500" height="300" controls style={{background:'rgba(0,0,0,0.8)'}}>
                        <source src={videoData?.confirmInfo} type="video/mp4"/>
                        您的浏览器不支持 video 标签。
                    </video>
                </div>
            </Modal>
            <Modal
                centered
                visible={isApply}
                footer={null}
                onCancel={e => {
                    setApply(false);
                    setAddressIndex(-1)
                }}
                getContainer={() => document.getElementById('Status')}
                bodyStyle={{
                    padding: '0', backgroundColor: '#FFFFFF',
                    width: 600,
                }}
            >
                <div style={{ textAlign: 'center', padding: '50px' }}>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{textJsonD['申请与商家合作']}</h3>
                    <div style={{
                        color: '#666666',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: id.sendSample === 1 ? 'flex-start' : 'center',
                        marginTop: '53px',
                        fontWeight: 500
                    }}>
                        <div>{textJsonD['总计佣金']}</div>
                        {
                            id.sendSample === 1 ?
                                id.asettleWay === 1 ?
                                    <div className='flex-ac'>
                                        <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                            $0
                                        </div>
                                        <div style={{ color: '#F7385B', fontSize: '14px', marginLeft: '10px' }}>({intl.get('ReleaseDemand')['寄样抵佣金']})</div>
                                        <div style={{ color: '#888888', marginLeft: '8px' }}>
                                            ({intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + id.duration + 's' : id.duration + 's内'})
                                        </div>
                                    </div>
                                    :
                                    <div className='flex-ac'>
                                        <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                            ${(userInfo.redAccountDetail?.videoQuotationDollar * 100 * Math.floor(id.duration / 60) / 100 || 0)}
                                        </div>
                                        <div style={{ color: '#888888', marginLeft: '8px' }}>
                                            (
                                            {textJson['红人视频单价']}
                                            {
                                                store.getState().language === 'en' ? ' in $' + (userInfo.redAccountDetail?.videoQuotationDollar || 0) + '/60s'
                                                    :
                                                    '$' + (userInfo.redAccountDetail?.videoQuotationDollar || 0) + '/' + intl.get('NewLogin')['60s内']
                                            } *
                                            {
                                                intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + id.duration + 's' : id.duration + 's内'
                                            }
                                            )
                                        </div>
                                    </div>
                                :
                                <div className='flex-acjc'>
                                    <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                        ${Math.floor(id.duration / 60) * (userInfo.redAccountDetail?.videoQuotationDollar || 0)}
                                    </div>
                                    <div style={{ color: '#888888', marginLeft: '8px' }}>
                                        ({
                                            textJson['红人视频单价']}{store.getState().language === 'en' ?
                                                ' in $' + (userInfo.redAccountDetail?.videoQuotationDollar || 0) + '/60s'
                                                :
                                                '$' + (userInfo.redAccountDetail?.videoQuotationDollar || 0) + '/' + intl.get('NewLogin')['60s内']} * {intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + id.durationH + 's' : id.durationH + 's内'
                                        })
                                    </div>
                                </div>
                        }
                    </div>
                    {id.sendSample === 1 ? <div>
                        <div style={{
                            color: '#666666',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '33px',
                            fontWeight: 500
                        }}>
                            {textJsonD['样品收件信息']}<span style={{ fontSize: '12px', color: '#888' }}>（{textJsonD['商家提供样品，请正确输入你的收件信息']}）</span>
                            <div style={{
                                width: '88px', height: '30px', borderRadius: '4px', cursor: 'pointer',
                                textAlign: 'center', lineHeight: '30px', color: '#F7385B',
                                border:'1px solid #F7385B', fontWeight: '500', fontSize:'13px'
                            }}
                                onClick={() => setIsAddress(true)} >
                                {intl.get('SmallShop')['添加地址']}
                            </div>
                        </div>
                        {
                            addressList?.map((item,index)=>{
                                return <div key={index} style={{width:'100%', display:'flex', alignItems:'center', marginTop:'17px', textAlign:'left', marginBottom:'10px'}}>
                                <div style={{width:'100%', display:'flex', alignItems:'center', height:'78px', border: '1px solid #E2E2E2', borderRadius:'4px', borderLeft:0,
                                }}>
                                    <img style={{height:'100%'}} src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/address.png" />
                                    <div style={{width:'100%', marginLeft:'29px'}}>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <div style={{width:'40%'}}>N : {item.contactName}</div><div>T : {item.phone}</div>
                                        </div>
                                        <div>{item.address}, {item.postCode}</div>
                                    </div>
                                </div>
                                <Radio checked={addressIndex == index} 
                                    onChange={e=>setAddressIndex(index)} style={{marginLeft:'19px'}} />
                            </div>
                            })
                        }
                    </div> : ''}
                    <div style={{
                        width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                        textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '0px auto', marginTop: '47px',
                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                    }}
                        onClick={() => submit()} >
                        {textJsonD['确定申请']}
                    </div>
                    <div style={{ fontSize: '12px', color: '#888888', marginTop: '12px' }}>
                        {textJson['温馨提示：当商家同意与你合作，请准时在截稿日期前完成合作，过期没有上传将会扣除平台信用分']}
                    </div>
                </div>
            </Modal>

            <Modal
                centered
                visible={isAddress}
                footer={null}
                onCancel={e => {
                    setIsAddress(false);
                    setAddParams({})
                }}
                getContainer={() => document.getElementById('Status')}
                bodyStyle={{
                    padding: '0', backgroundColor: '#FFFFFF',
                    width: 600,
                }}
            >
                <div id='login' style={{ textAlign: 'center', padding: '50px' }}>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom:'30px' }}>{intl.get('SmallShop')['添加地址']}</h3>
                    <div>
                        <div style={{ marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Input value={addParams.consigneeName} onChange={e => addChange(e.target.value, 'consigneeName')} style={{ width: '244px', height: '48px', borderRadius: '4px' }} placeholder={textJsonD['请输入收件人姓名']} />
                            <Input value={addParams.consigneePhone} onChange={e => addChange(e.target.value, 'consigneePhone')} style={{ width: '244px', height: '48px', borderRadius: '4px' }} placeholder={textJsonD['请输入收件人手机号']} />
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Input value={addParams.consigneePostCode} onChange={e => addChange(e.target.value, 'consigneePostCode')} style={{ width: '100%', height: '48px', borderRadius: '4px' }} placeholder={textJsonD['请输入邮编']} />
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Input value={addParams.consigneeSpecificAddress} onChange={e => addChange(e.target.value, 'consigneeSpecificAddress')} style={{ width: '100%', height: '48px', borderRadius: '4px' }} placeholder={textJsonD['请输入具体的街道门牌']} />
                        </div>
                    </div>
                    <div style={{
                        width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                        textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '38px auto', marginBottom: '12px',
                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                    }}
                        onClick={() => addAddress()} >
                        {intl.get('SmallShop')['确定添加']}
                    </div>
                </div>
            </Modal>        
            <div id='BloggerRanking'>
                {isPk ? <PkModel clickFun={() => setIsPk(false)} pkResultList={pkResultList} /> : ''}
            </div>
        </div>
    )
}
export default withRouter(Status);